<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="6" :sm="24">-->
<!--              <a-form-item :label="$t('用户充值.用户id')" prop="userId">-->
<!--                <a-input v-model="queryParam.userId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.用户id')" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户充值.充值金额')" prop="rechargeMoney">
                <a-input v-model="queryParam.rechargeMoney" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.充值金额')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户充值.用户昵称')" prop="nickName">
                <a-input v-model="queryParam.nickName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.用户昵称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户充值.手机号')" prop="mobile">
                <a-input v-model="queryParam.mobile" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.手机号')" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <template v-if="advanced">-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('用户充值.到账虚拟币数量')" prop="acceptNum">-->
<!--                  <a-input v-model="queryParam.acceptNum" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.到账虚拟币数量')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('用户充值.第三方订单号')" prop="orderNo">-->
<!--                  <a-input v-model="queryParam.orderNo" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.第三方订单号')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('用户充值.创建人')" prop="createBy">-->
<!--                  <a-input v-model="queryParam.createBy" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.创建人')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('用户充值.编辑人')" prop="updateBy">-->
<!--                  <a-input v-model="queryParam.updateBy" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.编辑人')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('用户充值.到账用户ID')" prop="acceptUserId">-->
<!--                  <a-input v-model="queryParam.acceptUserId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.到账用户ID')" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建开始时间')" prop="createBeginTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime"-->
<!--                     :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="6" :sm="24">-->
<!--                <a-form-item :label="$t('通用.文本.创建结束时间')" prop="createEndTime">-->
<!--                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime"-->
<!--                     :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"-->
<!--                     valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->

<!--            </template>-->
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
<!--      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:recharge:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:recharge:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:recharge:remove']">
          <a-icon type="delete" />{{$t('通用.按钮.删除')}}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['user:recharge:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>-->
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleDetail(record)">
            <a-icon type="eye" />{{$t('通用.按钮.查看')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <!-- 添加详情弹框 -->
      <a-modal
        :title="$t('用户充值.详情')"
        :visible="detailVisible"
        @cancel="handleDetailCancel"
        :footer="null"
        width="800px"
      >
        <a-descriptions :column="2" bordered>
          <a-descriptions-item :label="$t('用户充值.用户昵称')">
            {{ detailForm.nickName }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('用户充值.手机号')">
            {{ detailForm.mobile }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('用户充值.充值金额')">
            {{ detailForm.rechargeMoney }}
          </a-descriptions-item>
<!--          <a-descriptions-item :label="$t('用户充值.到账虚拟币数量')">
            {{ detailForm.acceptNum }}
          </a-descriptions-item>-->
<!--          <a-descriptions-item :label="$t('用户充值.状态')">
            {{ detailForm.status === 1 ? '成功' : '失败' }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('用户充值.充值类型')">
            {{ detailForm.rechargeType === 1 ? '支付宝' : '微信' }}
          </a-descriptions-item>-->
<!--          <a-descriptions-item :label="$t('用户充值.第三方订单号')">
            {{ detailForm.orderNo }}
          </a-descriptions-item>-->
          <a-descriptions-item :label="$t('用户充值.创建时间')">
            {{ parseTime(detailForm.createTime) }}
          </a-descriptions-item>
        </a-descriptions>
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageRecharge,listRecharge, delRecharge, getRecharge } from '@/api/user/recharge'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Recharge',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: undefined,
        rechargeMoney: undefined,
        nickName: undefined,
        mobile: undefined,
        acceptNum: undefined,
        status: undefined,
        rechargeType: undefined,
        orderNo: undefined,
        createBy: undefined,
        updateBy: undefined,
        acceptUserId: undefined,
        orderType: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
       /* {
          title: this.$t('用户充值.主键id'),
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('用户充值.用户昵称'),
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户充值.手机号'),
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户充值.充值金额'),
          dataIndex: 'rechargeMoney',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('用户充值.到账虚拟币数量'),
          dataIndex: 'acceptNum',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: this.$t('用户充值.0-失败 1-成功'),
          dataIndex: 'status',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户充值.1：支付宝 2：微信'),
          dataIndex: 'rechargeType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户充值.第三方订单号'),
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: this.$t('用户充值.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: this.$t('用户充值.创建人'),
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户充值.编辑人'),
          dataIndex: 'updateBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户充值.到账用户ID'),
          dataIndex: 'acceptUserId',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: this.$t('用户充值.金类型：1是积分到账，2是余额到账'),
          dataIndex: 'orderType',
          ellipsis: true,
          align: 'center'
        }*/
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      // 添加详情相关数据
      detailVisible: false,
      detailForm: {}
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询用户充值列表 */
    getList () {
      this.loading = true
     pageRecharge(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        rechargeMoney: undefined,
        nickName: undefined,
        mobile: undefined,
        acceptNum: undefined,
        status: undefined,
        rechargeType: undefined,
        orderNo: undefined,
        createBy: undefined,
        updateBy: undefined,
        acceptUserId: undefined,
        orderType: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delRecharge(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('user/recharge/export',
            ...that.queryParam
          , `用户充值_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleDetail (record) {
      this.detailVisible = true
      getRecharge({"id":record.id}).then(response => {
        this.detailForm = response.data
      })
      /*getRecharge(record.id).then(response => {
        this.detailForm = response.data
      })*/
    },
    // 关闭详情弹框
    handleDetailCancel() {
      this.detailVisible = false
      this.detailForm = {}
    }
  }
}
</script>

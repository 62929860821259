import request from '@/utils/request'


// 查询推广列表
export function listBind(query) {
  return request({
    url: '/sys/bind/list',
    method: 'get',
    params: query
  })
}

// 查询推广分页
export function pageBind(query) {
  return request({
    url: '/sys/bind/page',
    method: 'get',
    params: query
  })
}

// 查询推广详细
export function getBind(data) {
  return request({
    url: '/sys/bind/detail',
    method: 'get',
    params: data
  })
}

// 新增推广
export function addBind(data) {
  return request({
    url: '/sys/bind/add',
    method: 'post',
    data: data
  })
}

// 修改推广
export function updateBind(data) {
  return request({
    url: '/sys/bind/edit',
    method: 'post',
    data: data
  })
}

// 删除推广
export function delBind(data) {
  return request({
    url: '/sys/bind/delete',
    method: 'post',
    data: data
  })
}

import request from '@/utils/request'


// 查询店铺商品分类列表
export function listGoodsCategory(query) {
  return request({
    url: '/shop/goods/category/list',
    method: 'get',
    params: query
  })
}

// 查询店铺商品分类分页
export function pageGoodsCategory(query) {
  return request({
    url: '/shop/goods/category/page',
    method: 'get',
    params: query
  })
}

// 查询店铺商品分类详细
export function getGoodsCategory(data) {
  return request({
    url: '/shop/goods/category/detail',
    method: 'get',
    params: data
  })
}

// 新增店铺商品分类
export function addGoodsCategory(data) {
  return request({
    url: '/shop/goods/category/add',
    method: 'post',
    data: data
  })
}

// 修改店铺商品分类
export function updateGoodsCategory(data) {
  return request({
    url: '/shop/goods/category/edit',
    method: 'post',
    data: data
  })
}

// 删除店铺商品分类
export function delGoodsCategory(data) {
  return request({
    url: '/shop/goods/category/delete',
    method: 'post',
    data: data
  })
}
export function updateVisible(data) {
  return request({
    url: '/shop/goods/category/visible',
    method: 'post',
    data: data
  })
}

import request from '@/utils/request'


// 查询用户提现列表
export function listWithdraw(query) {
  return request({
    url: '/user/withdraw/list',
    method: 'get',
    params: query
  })
}

// 查询用户提现分页
export function pageWithdraw(query) {
  return request({
    url: '/user/withdraw/page',
    method: 'get',
    params: query
  })
}

// 查询用户提现详细
export function getWithdraw(data) {
  return request({
    url: '/user/withdraw/detail',
    method: 'get',
    params: data
  })
}

// 新增用户提现
export function addWithdraw(data) {
  return request({
    url: '/user/withdraw/add',
    method: 'post',
    data: data
  })
}

// 修改用户提现
export function updateWithdraw(data) {
  return request({
    url: '/user/withdraw/edit',
    method: 'post',
    data: data
  })
}

// 删除用户提现
export function delWithdraw(data) {
  return request({
    url: '/user/withdraw/delete',
    method: 'post',
    data: data
  })
}

import request from '@/utils/request'


// 查询收银订单列表
export function listCashier(query) {
  return request({
    url: '/order/cashier/list',
    method: 'get',
    params: query
  })
}

// 查询收银订单分页
export function pageCashier(query) {
  return request({
    url: '/order/cashier/page',
    method: 'get',
    params: query
  })
}

// 查询收银订单详细
export function getCashier(data) {
  return request({
    url: '/order/cashier/detail',
    method: 'get',
    params: data
  })
}

// 新增收银订单
export function addCashier(data) {
  return request({
    url: '/order/cashier/add',
    method: 'post',
    data: data
  })
}

// 修改收银订单
export function updateCashier(data) {
  return request({
    url: '/order/cashier/edit',
    method: 'post',
    data: data
  })
}

// 删除收银订单
export function delCashier(data) {
  return request({
    url: '/order/cashier/delete',
    method: 'post',
    data: data
  })
}

<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-descriptions :column="2" bordered>
        <a-descriptions-item :label="$t('用户充值.用户昵称')">
          {{ form.nickName }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.手机号')">
          {{ form.mobile }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.充值金额')">
          {{ form.rechargeMoney }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.到账虚拟币数量')">
          {{ form.acceptNum }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.状态')">
          {{ form.status === 1 ? '成功' : '失败' }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.充值类型')">
          {{ form.rechargeType === 1 ? '支付宝' : '微信' }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.第三方订单号')">
          {{ form.orderNo }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('用户充值.创建时间')">
          {{ parseTime(form.createTime) }}
        </a-descriptions-item>
      </a-descriptions>
      
      <div style="margin-top: 20px; text-align: center;">
        <a-button @click="goBack">{{ $t('通用.按钮.返回') }}</a-button>
      </div>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getRecharge } from '@/api/user/recharge'
import { parseTime } from '@/utils/ruoyi'

export default {
  name: 'RechargeDetail',
  data () {
    return {
      form: {}
    }
  },
  created () {
    const id = this.$route.query.id
    if (id) {
      this.getDetail(id)
    }
  },
  methods: {
    parseTime,
    getDetail (id) {
      getRecharge(id).then(response => {
        this.form = response.data
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script> 
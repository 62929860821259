<template>
  <a-modal
    :visible="visible"
    :title="$t('商户认证.选择银行')"
    @cancel="handleCancel"
    :width="900"
    :footer="null"
  >
    <a-form layout="inline" class="table-search-form">
      <a-row :gutter="48">
        <a-col :md="8" :sm="24">
          <a-form-item label="银行编码">
            <a-input
              v-model="queryParam.bankCode"
              placeholder="请输入银行编码"
              allowClear
              @pressEnter="handleSearch"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="银行名称">
            <a-input
              v-model="queryParam.bankName"
              placeholder="请输入银行名称"
              allowClear
              @pressEnter="handleSearch"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button style="margin-left: 8px" @click="handleReset">重置</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>

    <a-table
      :columns="columns"
      :data-source="list"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
      :row-selection="{ 
        type: 'radio',
        selectedRowKeys: selectedRowKeys, 
        onChange: onSelectChange 
      }"
    >
      <template slot="operation" slot-scope="text, record">
        <a @click="handleSelect(record)">{{ $t('通用.按钮.选择') }}</a>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { pageBankBranch } from '@/api/merchant/bankBranch'

export default {
  name: 'BankBranchModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      selectedRowKeys: [],
      queryParam: {
        bankName: undefined,
        bankCode: undefined
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '银行编码',
          dataIndex: 'bankCode'
        },
        {
          title: '银行名称',
          dataIndex: 'bankName'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      const params = {
        ...this.queryParam,
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      
      pageBankBranch(params).then(response => {
        this.list = response.data.records
        this.pagination.total = response.data.total
        this.loading = false
      })
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length > 0) {
        this.$emit('ok', selectedRows[0])
      }
    },
    handleCancel() {
      this.$emit('cancel')
    },
    handleSearch() {
      this.pagination.current = 1
      this.getList()
    },
    handleSelect(record) {
      this.$emit('ok', record)
    },
    handleReset() {
      this.queryParam = {
        bankName: undefined,
        bankCode: undefined
      }
      this.pagination.current = 1
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.table-search-form {
  margin-bottom: 16px;
  
  .table-page-search-submitButtons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }
}
</style>
<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="userId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.用户id')}}<template slot="title">{{$t('用户充值.用户id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('用户充值.用户id')" />
      </a-form-model-item>
      <a-form-model-item prop="rechargeMoney" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.充值金额')}}<template slot="title">{{$t('用户充值.充值金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.rechargeMoney"  :placeholder="$t('通用.输入.请输入')+$t('用户充值.充值金额')" />
      </a-form-model-item>
      <a-form-model-item prop="acceptNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.到账虚拟币数量')}}<template slot="title">{{$t('用户充值.到账虚拟币数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.acceptNum"  :placeholder="$t('通用.输入.请输入')+$t('用户充值.到账虚拟币数量')" />
      </a-form-model-item>
      <a-form-model-item prop="status" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.0-失败 1-成功')}}<template slot="title">{{$t('用户充值.0-失败 1-成功')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="rechargeType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.1：支付宝 2：微信')}}<template slot="title">{{$t('用户充值.1：支付宝 2：微信')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="orderNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.第三方订单号')}}<template slot="title">{{$t('用户充值.第三方订单号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.orderNo" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('用户充值.第三方订单号')" />
      </a-form-model-item>
      <a-form-model-item prop="createBy" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.创建人')}}<template slot="title">{{$t('用户充值.创建人')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.createBy" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.创建人')" />
      </a-form-model-item>
      <a-form-model-item prop="updateBy" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.编辑人')}}<template slot="title">{{$t('用户充值.编辑人')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.updateBy" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户充值.编辑人')" />
      </a-form-model-item>
      <a-form-model-item prop="acceptUserId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.到账用户ID')}}<template slot="title">{{$t('用户充值.到账用户ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.acceptUserId" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('用户充值.到账用户ID')" />
      </a-form-model-item>
      <a-form-model-item prop="orderType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户充值.资金类型：1是积分到账，2是余额到账')}}<template slot="title">{{$t('用户充值.资金类型：1是积分到账，2是余额到账')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRecharge, addRecharge, updateRecharge } from '@/api/user/recharge'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        rechargeMoney: null,

        acceptNum: null,

        status: '0',

        rechargeType: null,

        orderNo: null,

        createTime: null,

        createBy: null,

        updateBy: null,

        acceptUserId: null,

        orderType: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        rechargeMoney: null,
        acceptNum: null,
        status: '0',
        rechargeType: null,
        orderNo: null,
        createTime: null,
        createBy: null,
        updateBy: null,
        acceptUserId: null,
        orderType: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getRecharge({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateRecharge(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addRecharge(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<template>
  <global-footer class="footer custom-render">
<!--    <template v-slot:links>
&lt;!&ndash;      <a href="#" target="_blank">帮助</a>&ndash;&gt;
      <a href="http://www.qyg1001.com/private.html" target="_blank">隐私</a>
      <a href="http://www.qyg1001.com/user.html" target="_blank">条款</a>
    </template>-->
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@/components/ProLayout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>

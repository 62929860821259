<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="userType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.用户类型')}}<template slot="title">{{$t('推广.用户类型')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userType" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('推广.用户类型')" />
      </a-form-model-item>
      <a-form-model-item prop="nickName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.昵称')}}<template slot="title">{{$t('推广.昵称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.nickName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('推广.昵称')" />
      </a-form-model-item>
      <a-form-model-item prop="persionNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.邀请数量')}}<template slot="title">{{$t('推广.邀请数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.persionNum"  :placeholder="$t('通用.输入.请输入')+$t('推广.邀请数量')" />
      </a-form-model-item>
      <a-form-model-item prop="orderNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.下单数量')}}<template slot="title">{{$t('推广.下单数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.orderNum"  :placeholder="$t('通用.输入.请输入')+$t('推广.下单数量')" />
      </a-form-model-item>
      <a-form-model-item prop="money" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.金额')}}<template slot="title">{{$t('推广.金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.money"  :placeholder="$t('通用.输入.请输入')+$t('推广.金额')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="bindTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.绑定时间')}}<template slot="title">{{$t('推广.绑定时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.bindTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>-->
      <a-form-model-item prop="phoneNumber" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.手机号码')}}<template slot="title">{{$t('推广.手机号码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.phoneNumber" :maxLength="11" :placeholder="$t('通用.输入.请输入')+$t('推广.手机号码')" />
      </a-form-model-item>
      <a-form-model-item prop="avatar" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.头像地址')}}<template slot="title">{{$t('推广.头像地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.avatar" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('推广.备注')}}<template slot="title">{{$t('推广.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBind, addBind, updateBind } from '@/api/sys/bind'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        sysId: null,

        userType: null,

        nickName: null,

        userName: null,

        persionNum: null,

        orderNum: null,

        money: null,

        bindTime: null,

        email: null,

        phoneNumber: null,

        sex: null,

        avatar: null,

        loginDate: null,

        status: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userType: [
          { required: true, message: this.$t('推广.用户类型')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        nickName: [
          { required: true, message: this.$t('推广.昵称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        userName: [
          { required: true, message: this.$t('推广.真实姓名')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        persionNum: [
          { required: true, message: this.$t('推广.邀请数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderNum: [
          { required: true, message: this.$t('推广.下单数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        money: [
          { required: true, message: this.$t('推广.金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        sex: [
          { required: true, message: this.$t('推广.性别,0-保密 1-男 2-女')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        status: [
          { required: true, message: this.$t('推广.用户状态，1-正常，0禁用，禁用操作请注意清除token，同时不允许用户登录')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        sysId: null,
        userType: null,
        nickName: null,
        userName: null,
        persionNum: null,
        orderNum: null,
        money: null,
        bindTime: null,
        email: null,
        phoneNumber: null,
        sex: null,
        avatar: null,
        loginDate: null,
        status: 0,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBind({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateBind(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addBind(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
      <img alt="example" style="width: 100%" :src="previewUrl" />
    </a-modal>
    <a-card :bordered="false" class="ant-card-0">
      <!-- 操作 -->
      <div class="table-operations-none">
<!--        <a-button type="primary" size="small" @click="$refs.createForm.handleAdd()" v-hasPermi="['goods:category:add']">
          <a-icon type="plus" />{{$t('通用.按钮.新增')}}
        </a-button>-->
        <table-setting
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        :showHeader=false
        rowKey="id"
        :columns="columns"
        :data-source="list"

        :pagination="false"
      >
        <span slot="expandedRowRender" slot-scope="text">
          <category-sub-list ref="subList" :id="text.id" :level="text.level" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="categoryPic" slot-scope="text, record">
          <div>
            <img v-if="record.categoryPic" :src="record.categoryPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.categoryPic)" alt="none"/>
          </div>
        </template>
        <span slot="isFilter" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isFilter"/>
        </span>
        <span slot="isHomeVisible" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isHomeVisible"/>
        </span>
        <span slot="isVisible" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isVisible"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['goods:category:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['goods:category:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['goods:category:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['goods:category:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { pageGoodsCategory,listGoodsCategory, delGoodsCategory } from '@/api/shop/goodsCategory'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import CategorySubList from './subList'
import { tableMixin } from '@/store/table-mixin'
import bus from "@/utils/bus";

export default {
  name: 'CategorySubList',
  components: {
    CustomDictTag,
    CategorySubList,
    CreateForm
  },
  props: {
    level: {
      type: Number,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      expandedKeys: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: null,
        categoryPic: null,
        pid: null,
        level: 1,
        isVisible: null,
        isHomeVisible: null,
        sort: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '分类名称',
          ellipsis: true,
          width: '15%',
          dataIndex: 'categoryName',
          align: 'center'
        },
        {
          title: '商品分类图片',
          dataIndex: 'categoryPic',
          scopedSlots: { customRender: 'categoryPic' },
          align: 'center'
        },
        {
          title: '上级分类',
          dataIndex: 'parentCategoryName',
          align: 'center'
        },
        {
          title: '分类等级',
          dataIndex: 'level',
          align: 'center'
        },
        {
          title: '是否显示',
          dataIndex: 'isVisible',
          scopedSlots: { customRender: 'isVisible' },
          align: 'center'
        },
        {
          title: '是否首页展示',
          dataIndex: 'isHomeVisible',
          scopedSlots: { customRender: 'isHomeVisible' },
          align: 'center'
        },
        {
          title: '排序',
          dataIndex: 'sort',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '12%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    /**编辑页面接收到更新的数据 */
    bus.$on('updateCateChildrenList',()=>{
      this.getList()
    })
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询商品分类列表 */
    getList () {
      this.loading = true
      let query = {}
      query.pid=this.id
      query.level=this.level+1
     listGoodsCategory(query).then(response => {
        this.list = response.data
        this.loading = false
      })
    },
    onExpandCurrent(expandedKeys, row) {
      if (expandedKeys) {
        this.expandedKeys = [row.id]
      } else {
        this.expandedKeys = []
      }
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        categoryName: undefined,
        categoryPic: undefined,
        pid: undefined,
        level: undefined,
        isVisible: undefined,
        isHomeVisible: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGoodsCategory(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
  }
}
</script>
<style>
.table-operations-none {
  display: none;
}
.ant-card-0>.ant-card-body {
  padding: 0px;
}

</style>

import request from '@/utils/request'


// 查询用户充值列表
export function listRecharge(query) {
  return request({
    url: '/user/recharge/list',
    method: 'get',
    params: query
  })
}

// 查询用户充值分页
export function pageRecharge(query) {
  return request({
    url: '/user/recharge/page',
    method: 'get',
    params: query
  })
}

// 查询用户充值详细
export function getRecharge(data) {
  return request({
    url: '/user/recharge/detail',
    method: 'get',
    params: data
  })
}

// 新增用户充值
export function addRecharge(data) {
  return request({
    url: '/user/recharge/add',
    method: 'post',
    data: data
  })
}

// 修改用户充值
export function updateRecharge(data) {
  return request({
    url: '/user/recharge/edit',
    method: 'post',
    data: data
  })
}

// 删除用户充值
export function delRecharge(data) {
  return request({
    url: '/user/recharge/delete',
    method: 'post',
    data: data
  })
}

<template>
  <a-modal width="60%" :label-col="4" :wrapper-col="14" :visible="open" @ok="onOk" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>选择运营公司</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col>
              <!-- 条件搜索 -->
              <div class="table-page-search-wrapper">
                <a-form layout="inline">
                  <a-row :gutter="48">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="用户名称">
                        <a-input v-model="queryParam.userName" :placeholder="$t('通用.输入.请输入')" allow-clear />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="手机号">
                        <a-input v-model="queryParam.phoneNumber" :placeholder="$t('通用.输入.请输入')" allow-clear />
                      </a-form-item>
                    </a-col>
                    <a-col :md="!advanced && 8 || 24" :sm="24">
                  <span class="table-page-search-submitButtons"
                        :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                    <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                  </span>
                    </a-col>
                  </a-row>
                </a-form>
                <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
                  <img alt="example" style="width: 100%" :src="previewUrl" />
                </a-modal>
              </div>
              <!-- 操作 -->
              <!-- 创建/编辑用户,单独封装了组件 -->
              <create-form ref="createForm" :deptOptions="deptOptions" :statusOptions="dict.type['sys_normal_disable']"
                           :sexOptions="dict.type['sys_user_sex']" @ok="getList" @select-tree="getTreeselect" />
              <!-- 修改密码抽屉 -->
              <reset-password ref="resetPassword" />
              <!-- 分配角色模态框 -->
              <auth-role ref="authRole" />
              <!-- 上传文件 -->
              <import-excel ref="importExcel" @ok="getList" />
              <!-- 数据展示 -->
              <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
                       :row-selection="{ selectedRowKeys: selectedRowKeys,  type: 'radio',onChange: onSelectChange }" :pagination="false"
                       :bordered="tableBordered">
                <!-- tips: 此处的status 其实是后端的enable字段，所以status '0' 代表的是开启  status '1' 代表的是关闭 -->
                <span slot="status" slot-scope="text, record">
              <a-popconfirm ok-text="是" cancel-text="否" @confirm="confirmHandleStatus(record)"
                            @cancel="cancelHandleStatus(record)">
                <span slot="title">确认<b>{{ record.status ===  0 ? '启用' : '停用' }}</b>{{ record.nickName }}的用户吗?</span>
                <a-switch checked-children="开" un-checked-children="关" :checked="record.status === 1 " />
              </a-popconfirm>
            </span>
                <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span>
                <template slot="remark" slot-scope="text, record">
                  <div >
                    <img v-if="record.remark" :src="record.remark"  style="width:60px;height:62px;" @click="handleRecordPreview(record.remark)" alt="none"/>
                  </div>
                </template>
                <span slot="operation" slot-scope="text, record" v-if="record.id !== 1">
            </span>
              </a-table>
              <!-- 分页 -->
              <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
                            :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
                            @showSizeChange="onShowSizeChange" @change="changeSize" />
            </a-col>
          </a-row>

        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
    </a-card>
  </a-modal>
</template>

<script>

import { pageUser, delUser, changeUserStatus, deptTreeSelect } from '@/api/system/user'
import AuthRole from './AuthRole'
import ResetPassword from './ResetPassword'
import CreateForm from './CreateForm'
import ImportExcel from './ImportExcel'
import DeptTree from './DeptTree'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'CheckSelectUserindex',
  components: {
    AuthRole,
    ResetPassword,
    CreateForm,
    ImportExcel,
    DeptTree
  },
  mixins: [tableMixin],
  dicts: ['sys_normal_disable', 'sys_user_sex','sys_user_type'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        createBeginTime:undefined,
        createEndTime:undefined,
        userName: undefined,
        phoneNumber: undefined,
        status: undefined,
        userType: undefined,
        deptId: undefined
      },
      columns: [
        {
          title: '用户编号',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '用户名',
          dataIndex: 'userName',
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phoneNumber',
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          align: 'center'
        },
        // {
        //   title: '部门',
        //   dataIndex: 'dept.deptName',
        //   scopedSlots: { customRender: 'dept.deptName' },
        //   align: 'center'
        // },
       /* {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },*/
        {
          title: '分享二维码',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' },
          align: 'center'
        },
        /*{
          title: '二维码链接',
          dataIndex: 'remark',
          // scopedSlots: { customRender: 'remark' },
          align: 'center'
        },*/
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
    this.getDeptTree()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getTreeselect() { },
    /** 查询用户列表 */
    getList() {
      this.loading = true
      pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      }
      )
    },
    onClose() {
      this.open = false
      this.resetQuery()
    },
    onOk() {
      //判断有没有选择,拿到列表里选择的
      this.open = false
      this.$emit('goodsSelect', this.selectedRows)
      this.resetQuery()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    openGoods(starId, records, limitNum) {
      this.getList()
      this.open = true;
    },
    handleRecordPreview(url) {
      window.open(url, 'erWidow',"444");
    },
    /** 查询部门下拉树结构 */
    getDeptTree() {
      deptTreeSelect().then(response => {
        this.deptOptions = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        createBeginTime:undefined,
        createEndTime:undefined,
        userName: undefined,
        phoneNumber: undefined,
        status: undefined,
        deptId: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.handleQuery()
    },
    /* 用户状态修改 status 0开启 1关闭*/
    confirmHandleStatus(row) {
      const oldStatus = row.status
      const text = row.status === 0 ? '启用' : '关闭'
      const status = row.status === 1 ? 0 : 1
      changeUserStatus(row.id, status).then((res) => {
        if (res.success) {
          row.status = status
          this.$message.success(text + '成功', 3)
        }else{
          row.status = oldStatus
          this.$message.error(`${text}失败, ${res.message}`, 3)
        }
      }).catch(() => {
        this.$message.error(text + '异常', 3)
      })
    },
    cancelHandleStatus(row) {
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const userIds = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + userIds + '的数据',
        onOk() {
          return delUser(userIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('system/user/export', {
            ...that.queryParam
          }, `user_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>

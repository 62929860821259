<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="couponTypeId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.优惠券类型id')}}<template slot="title">{{$t('优惠券-用户关联.优惠券类型id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.couponTypeId"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.优惠券类型id')" />
      </a-form-model-item>
      <a-form-model-item prop="shopId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.店铺ID')}}<template slot="title">{{$t('优惠券-用户关联.店铺ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.店铺ID')" />
      </a-form-model-item>
      <a-form-model-item prop="couponCode" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.优惠券编码')}}<template slot="title">{{$t('优惠券-用户关联.优惠券编码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.couponCode" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.优惠券编码')" />
      </a-form-model-item>
      <a-form-model-item prop="userId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.领用人')}}<template slot="title">{{$t('优惠券-用户关联.领用人')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.领用人')" />
      </a-form-model-item>
      <a-form-model-item prop="useOrderId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.优惠券使用订单id')}}<template slot="title">{{$t('优惠券-用户关联.优惠券使用订单id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.useOrderId"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.优惠券使用订单id')" />
      </a-form-model-item>
      <a-form-model-item prop="createOrderId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.创建订单id(优惠券只有是完成订单发放的优惠券时才有值)')}}<template slot="title">{{$t('优惠券-用户关联.创建订单id(优惠券只有是完成订单发放的优惠券时才有值)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.createOrderId"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.创建订单id(优惠券只有是完成订单发放的优惠券时才有值)')" />
      </a-form-model-item>
      <a-form-model-item prop="money" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.面额')}}<template slot="title">{{$t('优惠券-用户关联.面额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.money"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.面额')" />
      </a-form-model-item>
      <a-form-model-item prop="detailMessage" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.优惠券详细信息')}}<template slot="title">{{$t('优惠券-用户关联.优惠券详细信息')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.detailMessage" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.优惠券详细信息')" />
      </a-form-model-item>
      <a-form-model-item prop="rangeGoodsId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.使用商品ID')}}<template slot="title">{{$t('优惠券-用户关联.使用商品ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.rangeGoodsId"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.使用商品ID')" />
      </a-form-model-item>
      <a-form-model-item prop="atLeast" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.满多少元使用 0代表无限制')}}<template slot="title">{{$t('优惠券-用户关联.满多少元使用 0代表无限制')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.atLeast"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.满多少元使用 0代表无限制')" />
      </a-form-model-item>
      <a-form-model-item prop="fetchTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.领取时间')}}<template slot="title">{{$t('优惠券-用户关联.领取时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.fetchTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="useTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.使用时间')}}<template slot="title">{{$t('优惠券-用户关联.使用时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.useTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="state" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.优惠券状态 1已领用')}}<template slot="title">{{$t('优惠券-用户关联.优惠券状态 1已领用')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.state"  :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.优惠券状态 1已领用')" />
      </a-form-model-item>
      <a-form-model-item prop="startTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.有效期开始时间')}}<template slot="title">{{$t('优惠券-用户关联.有效期开始时间')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="endTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.有效期结束时间')}}<template slot="title">{{$t('优惠券-用户关联.有效期结束时间')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="getType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.获取方式1领取2.兑换')}}<template slot="title">{{$t('优惠券-用户关联.获取方式1领取2.兑换')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="isNewCoupon" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.是否新人优惠券：0否1是')}}<template slot="title">{{$t('优惠券-用户关联.是否新人优惠券：0否1是')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isNewCoupon" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.是否新人优惠券：0否1是')" />
      </a-form-model-item>
      <a-form-model-item prop="needPop" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.是否需要弹窗：0否，1是')}}<template slot="title">{{$t('优惠券-用户关联.是否需要弹窗：0否，1是')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.needPop" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.是否需要弹窗：0否，1是')" />
      </a-form-model-item>
      <a-form-model-item prop="pupNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.弹窗次数')}}<template slot="title">{{$t('优惠券-用户关联.弹窗次数')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pupNum" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('优惠券-用户关联.弹窗次数')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('优惠券-用户关联.备注')}}<template slot="title">{{$t('优惠券-用户关联.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCouponReceive, addCouponReceive, updateCouponReceive } from '@/api/coupon/couponReceive'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        couponTypeId: null,

        shopId: null,

        couponCode: null,

        userId: null,

        useOrderId: null,

        createOrderId: null,

        money: null,

        detailMessage: null,

        rangeGoodsId: null,

        atLeast: null,

        fetchTime: null,

        useTime: null,

        state: null,

        startTime: null,

        endTime: null,

        getType: null,

        isNewCoupon: null,

        needPop: null,

        pupNum: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        couponTypeId: [
          { required: true, message: this.$t('优惠券-用户关联.优惠券类型id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        couponCode: [
          { required: true, message: this.$t('优惠券-用户关联.优惠券编码')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        money: [
          { required: true, message: this.$t('优惠券-用户关联.面额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        fetchTime: [
          { required: true, message: this.$t('优惠券-用户关联.领取时间')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        state: [
          { required: true, message: this.$t('优惠券-用户关联.优惠券状态 1已领用')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: this.$t('优惠券-用户关联.有效期开始时间')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: this.$t('优惠券-用户关联.有效期结束时间')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        getType: [
          { required: true, message: this.$t('优惠券-用户关联.获取方式1领取2.兑换')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        couponTypeId: null,
        shopId: null,
        couponCode: null,
        userId: null,
        useOrderId: null,
        createOrderId: null,
        money: null,
        detailMessage: null,
        rangeGoodsId: null,
        atLeast: null,
        fetchTime: null,
        useTime: null,
        state: null,
        startTime: null,
        endTime: null,
        getType: null,
        isNewCoupon: null,
        needPop: null,
        pupNum: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCouponReceive({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateCouponReceive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addCouponReceive(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('商户认证.商户签约名与商户证件主体名称一致')" prop="signName">
                <a-input v-model="queryParam.signName" :maxLength="32"
                         :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户签约名与商户证件主体名称一致')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商户昵称" prop="nickName">
                <a-input v-model="queryParam.nickName" :maxLength="32"
                         placeholder="请输入商户昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商户认证.手机号')" prop="mobile">
                  <a-input v-model="queryParam.mobile" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('商户认证.手机号')"
                           allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商户认证.商户联系人名称')" prop="contactName">
                  <a-input v-model="queryParam.contactName" :maxLength="32"
                           :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户联系人名称')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商户认证.商户联系人手机号')" prop="contactMobile">
                  <a-input v-model="queryParam.contactMobile" :maxLength="32"
                           :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户联系人手机号')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商户认证.认证状态')" prop="authentication">
                  <!--                  <a-select mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('商户认证.认证状态')"
                                              style="width: 100%" v-model="queryParam.authentication" allowClear>
                                      <a-select-option v-for="(item, index) in authentication" :value="item.type"
                                                       :key="index">
                                        {{ item.name }}
                                      </a-select-option>
                                    </a-select>-->
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商户认证.认证状态')" style="width: 100%"
                            v-model="queryParam.authentication" allowClear>
                    <a-select-option v-for="(item, index) in authentication" :value="item.value" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
<!--              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商户认证.系统用户ID')" prop="sysId">
                  <a-input v-model="queryParam.sysId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('商户认证.系统用户ID')"
                           allow-clear/>
                </a-form-item>
              </a-col>-->
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>{{ $t('通用.按钮.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon
                  type="redo"/>{{ $t('通用.按钮.重置') }}</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['company:company:add']">
                  <a-icon type="plus" />{{$t('通用.按钮.新增')}}
                </a-button>-->
        <a-button type="primary" @click="handleAdd()" v-hasPermi="['company:company:add']" v-if="!num">
          <a-icon type="plus"/>
          {{ $t('通用.按钮.新增') }}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="authentication" slot-scope="text, record">
           <a-select style="width: 50%" v-model="record.authentication" disabled="">
            <a-select-option v-for="(item, index) in authentication" :value="item.value" :key="index">
                      {{ item.label }}
            </a-select-option>
           </a-select>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['company:company:edit']"/>
          <!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['company:company:edit']">
                       <a-icon type="edit" />{{$t('通用.按钮.修改')}}
                    </a>-->
           <a @click="handleUpdate(record, undefined)" v-hasPermi="['company:company:edit']">
             <a-icon type="edit"/>{{ $t('通用.按钮.修改') }}
          </a>

           <a @click="companyAudit(record)" v-if="record.authentication===0 || record.authentication===3 ">
                  <a-icon type="cloud-upload"/>提交审核
           </a>

          <!--          <a-divider type="vertical" v-hasPermi="['company:company:remove']" />
                    <a @click="handleDelete(record)" v-hasPermi="['company:company:remove']">
                      <a-icon type="delete" />{{$t('通用.按钮.删除')}}
                    </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageCompany, listCompany, delCompany,companyAudit} from '@/api/company/company'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import bus from "@/utils/bus";

export default {
  name: 'Company',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      authentication: [
        {label: "待认证", value: 0},
        {label: "认证审核中", value: 1},
        {label: "协议待签署", value: 4},
        {label: "申请已完成", value: 2},
        {label: "申请已驳回", value: 3}
      ],
      list: [],
      num: false,
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        requestNo: undefined,
        businessRole: undefined,
        parentMerchantNo: undefined,
        licenceUrl: undefined,
        signName: undefined,
        nickName:undefined,
        signType: undefined,
        licenceNo: undefined,
        shortName: undefined,
        legalName: undefined,
        legalLicenceType: undefined,
        legalLicenceNo: undefined,
        legalLicenceFrontUrl: undefined,
        legalLicenceBackUrl: undefined,
        mobile: undefined,
        contactName: undefined,
        contactMobile: undefined,
        contactEmail: undefined,
        contactLicenceNo: undefined,
        province: undefined,
        city: undefined,
        district: undefined,
        address: undefined,
        settlementDirection: undefined,
        bankcode: undefined,
        bankAccountType: undefined,
        bankCardNo: undefined,
        authentication: undefined,
        ratio: undefined,
        merchantId: undefined,
        userAccount: undefined,
        shopId: undefined,
        sysId: undefined,
        operate: undefined,
        operateOne: undefined,
        operateTwo: undefined,
        operateThree: undefined,
        userId: undefined,
        extend: undefined,
        extendOne: undefined,
        extendTwo: undefined,
        extendThree: undefined,
        extendFour: undefined,
        extendFive: undefined,
        extendSix: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: this.$t('商户认证.公司主键id'),
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.${comment}'),
          dataIndex: 'requestNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户业务角色'),
          dataIndex: 'businessRole',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.上级商户编号'),
          dataIndex: 'parentMerchantNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户证件照片地址'),
          dataIndex: 'licenceUrl',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户签约名与商户证件主体名称一致'),
          dataIndex: 'signName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户签约类型:INDIVIDUAL(个体工商户)：一般为个体户、个体工商户、个体经营。ENTERPRISE(企业)：一般为有限公司、有限责任公司。INSTITUTION(事业单位)：包括国内各级、各类政府机构、事业单位等'),
          dataIndex: 'signType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户证件号码://统一社会信用代码证编号、事业单位法人证书编号、社会团体证书编号等，与商户签约类型匹配'),
          dataIndex: 'licenceNo',
          ellipsis: true,
          align: 'center'
        },*/
        /* {
           title: this.$t('商户认证.商户简称:将在收银台页面或者支付完成页向买家展示'),
           dataIndex: 'shortName',
           ellipsis: true,
           align: 'center'
         },
         {
           title: this.$t('商户认证.法人名称:请填写法人对应身份证件的姓名'),
           dataIndex: 'legalName',
           ellipsis: true,
           align: 'center'
         },*/
        /*{
          title: this.$t('商户认证.ID_CARD:法人证件类型，ID_CARD(法人身份证) FPRP(外国人永久居留身份证) PASSPORT(护照) HM_VISITORPASS(港澳居民往来内地通行证) TAIWAN(台胞证) SOLDIER(士兵证) OFFICERS(军官证)  不传默认身份证'),
          dataIndex: 'legalLicenceType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.法人证件号码，请填写法人对应身份证件号码'),
          dataIndex: 'legalLicenceNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.请上传带有人像面的法人证件照片'),
          dataIndex: 'legalLicenceFrontUrl',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.法人证件非人像面照片'),
          dataIndex: 'legalLicenceBackUrl',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('商户认证.手机号'),
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户联系人名称'),
          dataIndex: 'contactName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户联系人手机号'),
          dataIndex: 'contactMobile',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('商户认证.商户联系人邮箱'),
          dataIndex: 'contactEmail',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户联系人证件号'),
          dataIndex: 'contactLicenceNo',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: this.$t('商户认证.要求按照商户实际经营地址选择对应的省编号'),
          dataIndex: 'province',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.要求按照商户实际经营地址选择对应的市编号'),
          dataIndex: 'city',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.要求按照商户实际经营地址选择对应的区编号'),
          dataIndex: 'district',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.经营地址详细信息'),
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.开通结算产品时，必填，1、ACCOUNT(结算到支付账户)。2、BANKCARD(结算到银行账户)。对公账户/单位结算卡账户名称默认为商户签约名称；借记卡/存折账户名称默认为法人姓名'),
          dataIndex: 'settlementDirection',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.银行账户开户总行编码'),
          dataIndex: 'bankcode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.银行账户类型，企业：对公账户/单位结算卡，账户名称默认为商户签约名称。个体户：对公账户/借记卡/存折/单位结算卡UNIT_SETTLEMENT_CARD(单位结算卡)ENTERPRISE_ACCOUNT(对公账户)DEBIT_CARD(借记卡)PASSBOOK(存折)'),
          dataIndex: 'bankAccountType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.银行账户号码1、对公账户/单位结算卡账户名称默认为商户签约名称。2、借记卡/存折账户名称默认为法人姓名。'),
          dataIndex: 'bankCardNo',
          ellipsis: true,
          align: 'center'
        },

        {
          title: this.$t('商户认证.分账比例'),
          dataIndex: 'ratio',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.商户号'),
          dataIndex: 'merchantId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.企业用户号'),
          dataIndex: 'userAccount',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.店铺ID'),
          dataIndex: 'shopId',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: this.$t('商户认证.系统用户ID'),
          dataIndex: 'sysId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: "用户昵称",
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.认证状态'),
          dataIndex: 'authentication',
          scopedSlots: {customRender: 'authentication'},
          width: 300,
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.审核意见'),
          dataIndex: 'extendOne',
          width: 300,
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.签署地址'),
          dataIndex: 'extend',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('商户认证.运营公司id'),
          dataIndex: 'operate',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.运营公司上级运营公司'),
          dataIndex: 'operateOne',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.运营公司上级运营公司上级运行总公司'),
          dataIndex: 'operateTwo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.运营公司上级运营公司上级运行总公司总平台'),
          dataIndex: 'operateThree',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.店主id,外键 (user.id)'),
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展'),
          dataIndex: 'extend',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展1'),
          dataIndex: 'extendOne',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展2'),
          dataIndex: 'extendTwo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展3'),
          dataIndex: 'extendThree',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展4'),
          dataIndex: 'extendFour',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展5'),
          dataIndex: 'extendFive',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('商户认证.扩展6'),
          dataIndex: 'extendSix',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('商户认证.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: this.$t('商户认证.备注'),
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    /**编辑页面接收到更新的数据 */
    bus.$on('updateMerchantList', () => {
      this.getList()
    })
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    /** 查询商户认证列表 */
    getList() {
      this.loading = true
      pageCompany(this.queryParam).then(response => {debugger
        this.list = response.data.records
        if (response.data.total != 0) {
          this.num = true;
        }else {
          this.num = false;
        }


        this.total = response.data.total
        this.loading = false
      })
    },

    companyAudit(info) {
      companyAudit(info).then(response => {
        if (response.success) {
          this.$message.success('提交审核成功')
        } else {
          this.$message.error(res.data.message)
        }
        this.getList()
      })
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({
        path: 'merchantForm',
        query:
          {
            id: undefined
          }
      })
    },
    /** 编辑商品信息 **/
    handleUpdate(row) {
      this.$router.push({
        path: 'merchantForm',
        query:
          {
            id: row.id
          }
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        requestNo: undefined,
        businessRole: undefined,
        parentMerchantNo: undefined,
        licenceUrl: undefined,
        signName: undefined,
        nickName:undefined,
        signType: undefined,
        licenceNo: undefined,
        shortName: undefined,
        legalName: undefined,
        legalLicenceType: undefined,
        legalLicenceNo: undefined,
        legalLicenceFrontUrl: undefined,
        legalLicenceBackUrl: undefined,
        mobile: undefined,
        contactName: undefined,
        contactMobile: undefined,
        contactEmail: undefined,
        contactLicenceNo: undefined,
        province: undefined,
        city: undefined,
        district: undefined,
        address: undefined,
        settlementDirection: undefined,
        bankcode: undefined,
        bankAccountType: undefined,
        bankCardNo: undefined,
        authentication: undefined,
        ratio: undefined,
        merchantId: undefined,
        userAccount: undefined,
        shopId: undefined,
        sysId: undefined,
        operate: undefined,
        operateOne: undefined,
        operateTwo: undefined,
        operateThree: undefined,
        userId: undefined,
        extend: undefined,
        extendOne: undefined,
        extendTwo: undefined,
        extendThree: undefined,
        extendFour: undefined,
        extendFive: undefined,
        extendSix: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField === 'createTime') {
          this.queryParam.sortField = 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delCompany(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk() {
          that.download('company/company/export',
            ...that.queryParam
            , `商户认证_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>

import request from '@/utils/request'
import {guid} from "@/utils/ruoyi";


// 查询商品列表
export function listGoods(query) {
  return request({
    url: '/goods/goods/list',
    method: 'get',
    params: query
  })
}

// 查询商品分页
export function pageGoods(data) {
  data.date=guid()
  return request({
    url: '/goods/goods/page',
    method: 'post',
    data: data
  })
}
// 查询商品分页
export function activePage(data) {
  data.date=guid()
  return request({
    url: '/goods/goods/activePage',
    method: 'post',
    data: data
  })
}
// 查询商品详细
export function getGoods(data) {
  return request({
    url: '/goods/goods/detail',
    method: 'get',
    params: data
  })
}
// 查询商品详细
export function adminLogin() {
  return request({
    url: '/goods/goods/adminLogin',
    method: 'get',
  })
}
// 新增商品
export function addGoods(data) {
  return request({
    url: '/goods/goods/add',
    method: 'post',
    data: data
  })
}

// 修改商品
export function updateGoods(data) {
  return request({
    url: '/goods/goods/edit',
    method: 'post',
    data: data
  })
}

// 删除商品
export function delGoods(data) {
  return request({
    url: '/goods/goods/delete',
    method: 'post',
    data: data
  })
}


export function batchOn(data) {
  return request({
    url: '/goods/goods/batchOn',
    method: 'post',
    data: data
  })
}


export function batchOff(data) {
  return request({
    url: '/goods/goods/batchOff',
    method: 'post',
    data: data
  })
}

export function batchCopy(data) {
  return request({
    url: '/goods/goods/copy',
    method: 'post',
    data: data
  })
}

// 查询商品库存详细
export function getGoodsStock(data) {
  return request({
    url: '/goods/goods/detail/stock',
    method: 'get',
    params: data
  })
}
export function updateGoodsStock(data) {
  return request({
    url: '/goods/goods/edit/stock',
    method: 'post',
    data: data
  })
}
<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.手机号')" prop="mobile">
                <a-input v-model="queryParam.mobile"  @pressEnter="handleQuery":placeholder="$t('通用.输入.请输入')+$t('用户.手机号')" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.邮箱')" prop="email">
                <a-input v-model="queryParam.email" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('用户.邮箱')" allow-clear/>
              </a-form-item>
            </a-col>-->
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.昵称')" prop="nickName">
                <a-input v-model="queryParam.nickName" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('用户.昵称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.性别')" prop="sex">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('用户.性别')" v-model="queryParam.sex" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in this.customDict.SexEnum" :key="index" :value="d.type">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.用户状态')" prop="status">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('用户.用户状态')" v-model="queryParam.status" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in this.customDict.TFEnum" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>-->
<!--            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('用户.用户等级')" prop="status">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('用户.用户等级')" v-model="queryParam.levelId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in levelList" :key="index" :value="d.id">{{ d.levelName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>-->
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
<!--      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:user:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['biz:user:export']">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>-->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.SexEnum" :value="record.sex"/>
        </span>
<!--        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>-->
       <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
        <span  slot="statusFlag" slot-scope="text, record">
          <a-switch @change="(checked) => onStatusChange(checked,record)" :checked-children="$t('正常')"
                    :un-checked-children="$t('禁用')" :checked="record.statusFlag"/>
        </span>

        <template slot="avatar" slot-scope="text, record">
          <div>
            <img v-if="record.avatar" :src="record.avatar"  style="width:60px;height:62px;" @click="handleRecordPreview(record.avatar)" alt="none"/>
          </div>
        </template>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        :pageSizeOptions="pageSizeOptions"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageUser, listUser, delUser, updateUserStatus} from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import { tableMixin } from '@/store/table-mixin'
import {listLevel} from "@/api/user/level";
import {updateIsStudy} from "@/api/shop/shop";

export default {
  name: 'User',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      pageSizeOptions: ['10', '20', '50', '100'],
      list: [],
      levelList: [],

      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickName: null,
        sex: undefined,
        invitationCode: null,
        isNotice: null,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('用户.手机号'),
          dataIndex: 'mobile',
          align: 'center'
        },
        // {
        //   title: this.$t('用户.邮箱'),
        //   dataIndex: 'email',
        //   align: 'center'
        // },
        {
          title: this.$t('用户.昵称'),
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户.头像'),
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          align: 'center'
        },
        /*{
          title: this.$t('用户.真实姓名'),
          dataIndex: 'realName',
          ellipsis: true,
          align: 'center'
        },*/
        // {
        //   title: this.$t('用户.微信'),
        //   dataIndex: 'weChat',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: "qq",
        //   dataIndex: 'qq',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('用户.用户等级'),
        //   dataIndex: 'levelName',
        //   align: 'center'
        // },
        {
          title: this.$t('用户.性别'),
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          align: 'center'
        },
        {
          title: "账户余额",
          dataIndex: 'rechargeMoney',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title:  this.$t('用户.生日'),
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: this.$t('用户.用户状态'),
          dataIndex: 'statusFlag',
          scopedSlots: { customRender: 'statusFlag' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('用户.注册时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getLevelList();
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    onStatusChange(statusFlag, record){
      let data = {id: record.id, statusFlag: statusFlag}
      updateUserStatus(data).then(response => {
        if (response.success) {
          this.$message.success(
            this.$t('通用.文本.修改成功'),
            3
          );
          this.$emit('ok')
          record.statusFlag = statusFlag
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    getLevelList () {
      listLevel().then(response => {
        this.levelList = response.data
      })
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickName: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('biz/user/export', queryParam, `用户信息_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>

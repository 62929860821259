import request from '@/utils/request'


// 查询优惠券-用户关联列表
export function listCouponReceive(query) {
  return request({
    url: '/coupon/coupon/receive/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券-用户关联分页
export function pageCouponReceive(query) {
  return request({
    url: '/coupon/coupon/receive/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券-用户关联详细
export function getCouponReceive(data) {
  return request({
    url: '/coupon/coupon/receive/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券-用户关联
export function addCouponReceive(data) {
  return request({
    url: '/coupon/coupon/receive/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券-用户关联
export function updateCouponReceive(data) {
  return request({
    url: '/coupon/coupon/receive/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券-用户关联
export function delCouponReceive(data) {
  return request({
    url: '/coupon/coupon/receive/delete',
    method: 'post',
    data: data
  })
}

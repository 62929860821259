<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div v-show="pagetype === 1">
      <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.商品编号')" prop="goodsCode">
                  <a-input v-model="queryParam.goodsCode" @pressEnter="handleQuery" :maxLength="20" :placeholder="$t('通用.输入.请输入')+$t('商品.商品编号')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.商品名称')" prop="goodsName">
                  <a-input v-model="queryParam.goodsName" @pressEnter="handleQuery" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('商品.商品名称')" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('商品.商品类型')" prop="goodsType">
                  <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.商品类型')" style="width: 100%" v-model="queryParam.goodsType" allowClear>
                    <a-select-option v-for="(item, index) in customDict.GoodsTypeEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <template v-if="advanced">
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.商品分类')" prop="categoryId">
                    <a-tree-select v-model="queryParam.categoryId" style="width: 100%"
                                   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="cateOptions" :placeholder="$t('通用.输入.请选择')+$t('商品.商品分类')"
                                   :replaceFields="{ children: 'children', title: 'categoryName', key: 'id', value: 'id' }"
                                   tree-default-expand-all allow-clear>
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.商品状态')" prop="status">
                    <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.商品状态')" style="width: 100%" v-model="queryParam.status" allowClear>
                      <a-select-option v-for="(item, index) in customDict.GoodsOnOffEnum" :value="item.type" :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.品牌')" prop="brandId">
                    <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.品牌')" style="width: 100%" v-model="queryParam.brandId" allowClear>
                      <a-select-option v-for="(item, index) in this.brandList" :value="item.id" :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.标签')" prop="labelList">
                    <a-select mode="multiple"  :placeholder="$t('通用.输入.请选择')+$t('商品.标签')" style="width: 100%" v-model="queryParam.labelList" allowClear>
                      <a-select-option v-for="(item, index) in labelList" :value="item.id+''" :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.是否推荐')" prop="isRecommend">
                    <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否推荐')" style="width: 100%" v-model="queryParam.isRecommend" allowClear>
                      <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                       :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.是否新品')" prop="isNew">
                    <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否新品')" style="width: 100%" v-model="queryParam.isNew" allowClear>
                      <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                       :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.是否限购')" prop="isRestriction">
                    <a-select :placeholder="$t('通用.输入.请选择')+$t('商品.是否限购')" style="width: 100%" v-model="queryParam.isRestriction" allowClear>
                      <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                       :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
<!--                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('商品.售卖结束时间')" prop="saleEndTime">
                    <a-date-picker style="width: 100%" v-model="queryParam.saleEndTime"
                                   show-time
                                   format="YYYY-MM-DD HH:mm:ss"
                                   valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                  </a-form-item>
                </a-col>-->
              </template>
              <a-col :md="!advanced && 6 || 24" :sm="24">
                <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}
                    <a-icon :type="advanced ? 'up' : 'down'"/>
                  </a>
                </span>
              </a-col>
            </a-row>
          </a-form>
          <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
            <img alt="example" style="width: 100%" :src="previewUrl" />
          </a-modal>
        </div>
      <!-- 操作 -->
        <div class="table-operations">
          <a-button type="primary" @click="handleAdd()" v-hasPermi="['goods:goods:add']">
            <a-icon type="plus" />{{$t('通用.按钮.新增')}}
          </a-button>
          <a-button type="primary" :disabled="single" @click="handleUpdate(ids)" v-hasPermi="['goods:goods:add']">
            <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a-button>
          <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['goods:goods:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a-button>
          <a-button type="primary" :disabled="multiple" @click="handleBatchCopy" v-hasPermi="['goods:goods:copy']">
            <a-icon type="copy" />{{$t('商品.批量复制')}}
          </a-button>
          <a-button type="primary" :disabled="multiple" @click="handleOn" v-hasPermi="['goods:goods:batchOn']">
            <a-icon type="check" />{{$t('商品.批量上架')}}
          </a-button>
          <a-button type="danger" :disabled="multiple" @click="handleOff" v-hasPermi="['goods:goods:batchOff']">
            <a-icon type="stop" />{{$t('商品.批量下架')}}
          </a-button>
<!--          <a-button type="primary" @click="handleExport" v-hasPermi="['goods:goods:export']">
            <a-icon type="download" />{{$t('通用.按钮.导出')}}
          </a-button>
          <a-button type="primary" :disabled="multiple" @click="handleSelectExport" v-hasPermi="['goods:goods:export']">
            <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
          </a-button>-->
          <table-setting
            :style="{float: 'right'}"
            :table-size.sync="tableSize"
            v-model="columns"
            :refresh-loading="loading"
            @refresh="getList" />
        </div>
        <!-- 数据展示 -->
        <a-table
          :scroll="{ x: '130%' }"
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          @change="handleSortChange"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
          :bordered="tableBordered"
        >
          <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>

          <template slot="picture" slot-scope="text, record">
            <div >
              <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
            </div>
          </template>
          <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
          </span>
          <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
          <span slot="starId" slot-scope="text, record">
            <a @click="handleJumpStar(record.starId)">{{record.starName}}</a>
          </span>
          <span slot="stock" slot-scope="text, record">
            <a @click="updateSkuStock(record.id)">{{record.stock}}</a>
          </span>
          <span slot="status" slot-scope="text, record">
            <a-select style="width: 50%" v-model="record.status"  @change="onStatusChange(record)">
              <a-select-option v-for="(item, index) in customDict.GoodsOnOffEnum" :value="item.type"
                               :key="index">
                        {{ item.name }}
              </a-select-option>
            </a-select>
          </span>
          <span slot="operation" slot-scope="text, record">
            <a-divider type="vertical" v-hasPermi="['goods:goods:edit']" />
            <a @click="handleUpdate(record, undefined)" v-hasPermi="['goods:goods:edit']">
               <a-icon type="edit" />{{$t('通用.按钮.修改')}}
            </a>
             <a-divider type="vertical" v-hasPermi="['goods:goods:copy']" />
            <a @click="handleCopy(record, undefined)" v-hasPermi="['goods:goods:copy']">
               <a-icon type="copy" />{{$t('通用.按钮.复制')}}
            </a>
            <a-divider type="vertical" v-hasPermi="['goods:goods:remove']" />
            <a @click="handleDelete(record)" v-hasPermi="['goods:goods:remove']">
              <a-icon type="delete" />{{$t('通用.按钮.删除')}}
            </a>
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :pageSizeOptions="pageSizeOptions"
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => getTotal(total)"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </div>
      <update-sku-stock ref="updateSkuStock" @ok="getList"></update-sku-stock>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {batchOn, batchOff, delGoods, pageGoods, batchCopy} from '@/api/goods/goods'
import {allLabel} from '@/api/goods/label'
import CustomDictTag from "@/components/DictCustomTag";
import UpdateSkuStock from "./modules/UpdateSkuStock";
import {mapGetters} from 'vuex'
import {tableMixin} from '@/store/table-mixin'
import {listCategoryPlat} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";
import bus from '@/utils/bus'

export default {
  name: 'Goods',
  components: {
    CustomDictTag,
    UpdateSkuStock
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      categoryList: [{id: "0", categoryName: ''}],
      cateOptions: [],
      brandList: [],
      labelList: [],
      pagetype: 1,  // 1商品列表 2新增编辑商品页面
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        goodsCode: undefined,
        goodsName: undefined,
        goodsType: undefined,
        saleEndTime:undefined,
        categoryId: undefined,
        shopId: undefined,
        shopCategoryId: undefined,
        brandId: undefined,
        isRecommend: undefined,
        isNew: undefined,
        isRestriction: undefined,
        status: undefined,
        starName: undefined,
        labelList: undefined,
        prodArea: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          width: 100,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 300,
          fixed: 'left',
        },
       /* {
          title: this.$t('商品.包装单位'),
          dataIndex: 'unit',
        },*/
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 100,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 100,
          scopedSlots: {customRender: 'categoryId'},
        },
        /*   {
             title: this.$t('商品.店铺id'),
             dataIndex: 'shopId',
             align: 'center'
           },
           {
             title: this.$t('商品.商家商品分类表'),
             dataIndex: 'shopCategoryId',
             align: 'center'
           },*/
        {
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          sorter: 1,
          width: 100,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: {customRender: 'status'},
        },
        /*{
          title: this.$t('商品.售卖开始时间'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'saleStartTime',
        },
        {
          title: this.$t('商品.售卖结束时间'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'saleEndTime',
        },
        {
          title: this.$t('明星.明星名字'),
          scopedSlots: {customRender: 'starId'},
          dataIndex: 'starName',
        },*/
        {
          title: this.$t('商品.sku.库存'),
          dataIndex: 'stock',
          scopedSlots: {customRender: 'stock'},
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          width: '80px',
        },
        {
          title: this.$t('商品.市场价'),
          sorter: 1,
          width: 100,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          sorter: 1,
          width: 100,
          sortDirections: ['descend', 'ascend'],
          dataIndex: 'price',
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'id',
          width: 150,
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: {customRender: 'createTime'},
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 210,
          scopedSlots: {customRender: 'operation'},
        }
      ]
    };
  },
  filters: {
  },
  created () {
    this.getList()
    this.getBrandList();
    this.getCategoryList();
    this.getLabelList();
    /**编辑页面接收到更新的数据 */
    bus.$on('updateGoodsList',()=>{
      this.getList()
    })
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted() {
    this.resetQuery();
  },
  methods: {
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询商品列表 */
    getList () {
      this.loading = true
     pageGoods(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /*页面初始化时获取分类类别*/
    getCategoryList() {
      listCategoryPlat().then(response => {
        this.categoryList = response.data;
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.cateOptions = children
      })
    },

    /*页面初始化时获取标签类别*/
    getLabelList() {
      allLabel().then(response => {
        this.labelList = response.data;
      })
    },

    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        goodsCode: undefined,
        goodsName: undefined,
        goodsType: undefined,
        categoryId: undefined,
        saleEndTime:undefined,
        shopId: undefined,
        shopCategoryId: undefined,
        brandId: undefined,
        isRecommend: undefined,
        isNew: undefined,
        isRestriction: undefined,
        status: undefined,
        starName: undefined,
        prodArea: undefined,
        labelList: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    onStatusChange(record) {
      //商品快捷上下架
      let data = {"idList": [record.id]};
      if (record.status===1) {
        batchOn(data).then(() => {
          this.getList()
          this.$message.success(
            this.$t('商品.上架成功'),
            3
          )
        })
      } else if (record.status===0) {
        batchOff(data).then(() => {
          this.getList()
          this.$message.success(
            this.$t('商品.下架成功'),
            3
          )
        })
      }
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({
        path: 'goodsForm',
        query:
          {
            id: undefined
          }
      })
    },

    handleJumpStar(starId) {
      this.$router.push({
        name: 'Star',
        params:
          {
            id: starId
          }
      })
    },
    updateSkuStock(goodsId){
      this.$refs.updateSkuStock.show(goodsId);
    },
    /** 编辑商品信息 **/
    handleUpdate(row) {
      this.$router.push({
        path: 'goodsForm',
        query:
          {
            id: row.id
          }
      })
    },
    handleCopy(row) {
      this.$router.push({
        path: 'goodsForm',
        query:
          {
            id: row.id,
            "copy":true
          }
      })
    },
    handleBatchCopy (row) {
      var that = this
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      let ids = this.ids
      this.$confirm({
        title: this.$t('商品.确认复制'),
        onOk () {
          let data = {"idList":ids}
          return batchCopy(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('商品.复制成功'),
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGoods(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('通用.文本.删除成功'),
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 批量上架操作 */
    handleOn (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      let data = {"idList": this.ids};
      this.$confirm({
        title: this.$t('商品.确认上架'),
        onOk () {
          return batchOn(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('商品.上架成功'),
                3
              )
            })
        },
        onCancel () {
          //取消清空ids列表
          that.ids = []
        }
      })
    },

    /** 批量下架操作 */
    handleOff(row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      let data = {"idList": this.ids};
      this.$confirm({
        title: this.$t('商品.确认下架'),
        onOk () {
          return batchOff(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                that.$t('商品.下架成功'),
                3
              )
            })
        },
        onCancel () {
          //取消清空ids列表
          that.ids = []
        }
      })
    },


    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('goods/goods/export',
            that.queryParam
          , `商品_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('goods/goods/export', queryParam, `商品_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple=true
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple=true
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>

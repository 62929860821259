<template>
  <page-header-wrapper>
    <template v-slot:content>
      <div class="page-header-content">
        <div class="avatar">
          <a-avatar size="large" :src="avatar"/>
        </div>
        <div class="content">
          <div class="content-title">
            {{ timeFix }}，{{ nickname }}<span class="welcome-text"></span>
          </div>
          <div> {{ postGroup }} | {{ user.dept && user.dept.deptName }}</div>
        </div>
      </div>

      <a-card>
        <div>
          <a-row>
            <a-col :span="8" style="background: rgb(0, 0, 255, 0.1); padding: 20px">
              <div class="grid-content bg-purple">
                <!--                <div class="left" >-->
                <!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: blue"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">总交易额(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.payAmount) }}</p>
                  <div :style="{color:dataList.yesterday.payAmount>dataList.today.payAmount?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.payAmount) }}元</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.payAmount)>Number(dataList.today.payAmount)"/>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.payAmount)<=Number(dataList.today.payAmount)"/>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8" style="background: rgb(34, 139, 34, 0.1); margin: 0 14px; padding: 20px">
              <div class="grid-content bg-purple-light">
                <!--                <div class="left ">-->
                <!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">退款金额(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.refundAmount) }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.refundAmount) }}元</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.refundAmount)<Number(dataList.today.refundAmount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="7" style="background: rgb(0, 0, 255, 0.1); padding: 20px;margin-right: 10px;">
              <div class="grid-content bg-purple">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: green"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">客单价(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.customerPrice) }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.customerPrice) }}元</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.customerPrice)<Number(dataList.today.customerPrice)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="6" style="background: rgb(106, 90, 205, 0.1); padding: 20px">
              <div class="grid-content bg-purple" @click.prevent="handleLink('/order/order')">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="account-book" style="font-size: 2.5rem;color: deepskyblue"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">总订单数(今日)</p>
                  <p class="title color margin-b-10">{{ dataList.today.orderCount }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.orderCount)>Number(dataList.today.orderCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.orderCount }}单</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.orderCount)>Number(dataList.today.orderCount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.orderCount)<Number(dataList.today.orderCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="6" style="background: rgb(0, 0, 255, 0.1); margin: 0 10px; padding: 20px">
              <div class="grid-content bg-purple-light" @click.prevent="handleLink('/order/refund')">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="account-book" style="font-size: 2.5rem;color: red"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">退款单数(今日)</p>
                  <p class="title color margin-b-10">{{ dataList.today.refundCount }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.refundCount)>Number(dataList.today.refundCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.refundCount }}单</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.refundCount)>Number(dataList.today.refundCount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.refundCount)<Number(dataList.today.refundCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="5" style="background: rgb(138, 43, 266, 0.1); margin: 0 10px;  padding: 20px">
              <div class="grid-content bg-purple" @click.prevent="handleLink('/user/tbUser')">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="user-add" style="font-size: 2.5rem;color: #bae7ff"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">今日新增用户数</p>
                  <p class="title color margin-b-10">{{ dataList.today.userCount || 0 }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.userCount)>Number(dataList.userCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.userCount || 0 }}个</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.userCount)>Number(dataList.today.userCount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.userCount)<Number(dataList.today.userCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="5" style="background: rgb(138, 43, 266, 0.1); padding: 20px">
              <div class="grid-content bg-purple">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="usergroup-add" style="font-size: 2.5rem;color: #bae7ff"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">今日新增邀请用户数</p>
                  <p class="title color margin-b-10">{{ dataList.today.inviteUserCount || 0 }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.inviteUserCount)>Number(dataList.inviteUserCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.inviteUserCount || 0 }}个</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.inviteUserCount)>Number(dataList.today.inviteUserCount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.inviteUserCount)<Number(dataList.today.inviteUserCount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="7" style="background: rgb(255, 192, 203, 0.1); padding: 20px">
              <div class="grid-content bg-purple">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: red"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">商品金额(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.goodsAmount) }}</p>
                  <!-- <p class="title color">¥ 200</p> -->
                  <div
                    :style="{color:Number(dataList.yesterday.goodsAmount)>Number(dataList.today.goodsAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.goodsAmount) }}元</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.goodsAmount)>Number(dataList.today.goodsAmount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.goodsAmount)<Number(dataList.today.goodsAmount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="7" style="background: rgb(138, 43, 266, 0.1); margin: 0 14px; padding: 20px">
              <div class="grid-content bg-purple-light">
                <!--                <div class="left cornflowerblue">-->
                <!--                  <a-icon type="money-collect" style="font-size: 2.5rem;color: orange"/>-->
                <!--                </div>-->
                <div class="left">
                  <p class="title">运费(今日)</p>
                  <p class="title color margin-b-10">¥ {{ (dataList.today.shippingAmount) }}</p>
                  <div
                    :style="{color:Number(dataList.yesterday.shippingAmount)>Number(dataList.today.shippingAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.shippingAmount) }}元</span>
                    <a-icon type="arrow-down"
                            v-if="Number(dataList.yesterday.shippingAmount)>Number(dataList.today.shippingAmount)"></a-icon>
                    <a-icon type="arrow-up"
                            v-if="Number(dataList.yesterday.shippingAmount)<Number(dataList.today.shippingAmount)"></a-icon>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>

      </a-card>
      <a-card>
        <a-row>
          <a-col :span="12">
            <div style="padding: 5px">
              <div class="right two" >
                <span :class="isOrderSelect === 'days'?'isActive': ''" @click="handelOrderClick('days')">按天</span>
                <span :class="isOrderSelect === 'months'?'isActive': ''" @click="handelOrderClick('months')">按月</span>
                <span :class="isOrderSelect === 'years'?'isActive': ''" @click="handelOrderClick('years')" style="margin-right:10px;">按年</span>
              </div>
              <a-range-picker valueFormat="YYYY-MM-DD"  @change="orderAmountDateChange"  size="large"/>
            </div>
            <div id="orderAmountChart" style="height: 400px"></div>
          </a-col>

          <a-col :span="12">
            <div style="padding: 5px">
              <div class="right two" >
                <span :class="isOrderCountSelect === 'days'?'isActive': ''" @click="handelOrderCountClick('days')">按天</span>
                <span :class="isOrderCountSelect === 'months'?'isActive': ''" @click="handelOrderCountClick('months')">按月</span>
                <span :class="isOrderCountSelect === 'years'?'isActive': ''" @click="handelOrderCountClick('years')" style="margin-right:10px;">按年</span>
              </div>
              <a-range-picker  valueFormat="YYYY-MM-DD"  @change="orderCountDateChange"  size="large"/>
            </div>
            <div id="orderCountChart" style="height: 400px"></div>
          </a-col>

        </a-row>
      </a-card>
      <a-card>
        <div>
          <!-- 消费排行榜 -->
          <a-row :gutter="16" style="margin-top: 16px">
            <a-col :span="8">
              <div class="rank-box">
                <div class="rank-header" @click="toggleUserRank" style="cursor: pointer">
                  <div class="rank-title">
                    <span style="color: #1890ff">消费排行榜</span>
                    <a-icon :type="userRankExpanded ? 'up' : 'down'"/>
                  </div>
                </div>

                <!-- 使用v-show控制内容显示/隐藏 -->
                <div v-if="userRankExpanded">
                  <div class="filter-box">
                    <div class="btn-group">
                      <span :class="isUserSelect === 'days'?'isActive': ''"
                            @click="handleUserClick('days')">按天</span>
                      <span :class="isUserSelect === 'months'?'isActive': ''"
                            @click="handleUserClick('months')">按月</span>
                      <span :class="isUserSelect === 'years'?'isActive': ''"
                            @click="handleUserClick('years')">按年</span>
                    </div>
                    <a-range-picker valueFormat="YYYY-MM-DD" @change="userDateChange" size="large"/>
                  </div>
                  <div style="height: 340px">
                    <a-table
                      :pagination="false"
                      :columns="userColumns"
                      :data-source="userList"
                      :loading="userLoading"
                    ></a-table>

                  </div>
                  <div class="justify-end flex">
                    <a-pagination :total="userTotal" :showTotal="total => getTotal(total)"
                                  @change="handleUserTableChange" :current="userParam.pageNum" :showSizeChanger="false"
                                  :showQuickJumper="true" :page-size="userParam.pageSize"/>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="rank-box">
                <div class="rank-header" @click="toggleGoodsRank" style="cursor: pointer">
                  <div class="rank-title">
                    <span style="color: #52c41a">商品排行榜</span>
                    <a-icon :type="goodsRankExpanded ? 'up' : 'down'"/>
                  </div>
                </div>

                <!-- 使用v-show控制内容显示/隐藏 -->
                <div v-if="goodsRankExpanded">
                  <div class="filter-box">
                    <div class="btn-group">
                      <span :class="isGoodsSelect === 'days'?'isActive': ''"
                            @click="handleGoodsClick('days')">按天</span>
                      <span :class="isGoodsSelect === 'months'?'isActive': ''"
                            @click="handleGoodsClick('months')">按月</span>
                      <span :class="isGoodsSelect === 'years'?'isActive': ''"
                            @click="handleGoodsClick('years')">按年</span>
                    </div>
                    <a-range-picker valueFormat="YYYY-MM-DD" @change="goodsDateChange" size="large"/>
                  </div>

                  <div style="height: 340px">
                    <a-table
                      :pagination="false"
                      :columns="columns"
                      :data-source="goodsList"
                      :loading="goodsLoading"
                    ></a-table>

                    <div class="justify-end flex">
                      <a-pagination
                        :total="goodsTotal"
                        :showTotal="total => getTotal(total)"
                        @change="handleGoodsTableChange"
                        :current="goodsParam.pageNum"
                        :showSizeChanger="false"
                        :showQuickJumper="true"
                        :page-size="goodsParam.pageSize"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="rank-box">
                <div class="rank-header" @click="toggleStoreRank" style="cursor: pointer">
                  <div class="rank-title">
                    <span style="color: #f5222d">店铺排行榜</span>
                    <a-icon :type="storeRankExpanded ? 'up' : 'down'"/>
                  </div>
                </div>

                <!-- 使用v-show控制内容显示/隐藏 -->
                <div v-if="storeRankExpanded">
                  <div class="filter-box">
                    <div class="btn-group">
                      <span :class="isStoreSelect === 'days'?'isActive': ''"
                            @click="handleStoreClick('days')">按天</span>
                      <span :class="isStoreSelect === 'months'?'isActive': ''"
                            @click="handleStoreClick('months')">按月</span>
                      <span :class="isStoreSelect === 'years'?'isActive': ''"
                            @click="handleStoreClick('years')">按年</span>
                    </div>
                    <a-range-picker valueFormat="YYYY-MM-DD" @change="storeDateChange" size="large"/>
                  </div>

                  <div style="height: 340px">
                    <a-table
                      :pagination="false"
                      :columns="storeColumns"
                      :data-source="storeList"
                      :loading="storeLoading"
                    ></a-table>

                    <div class="justify-end flex">
                      <a-pagination
                        :total="storeTotal"
                        :showTotal="total => getTotal(total)"
                        @change="handleStoreTableChange"
                        :current="storeParam.pageNum"
                        :showSizeChanger="false"
                        :showQuickJumper="true"
                        :page-size="storeParam.pageSize"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <!--        <div>
                  <a-row :gutter="16" style="margin-top: 16px">
                    <a-col :span="30">
                      <div class="rank-box">
                        <div class="rank-header" @click="toggleGoodsRank" style="cursor: pointer">
                          <div class="rank-title">
                            商品排行榜
                            <a-icon :type="goodsRankExpanded ? 'up' : 'down'" />
                          </div>
                        </div>

                        &lt;!&ndash; 使用v-show控制内容显示/隐藏 &ndash;&gt;
                        <div v-show="goodsRankExpanded">
                          <div class="filter-box">
                            <div class="btn-group">
                              <span :class="isGoodsSelect === 'days'?'isActive': ''"
                                    @click="handleGoodsClick('days')">按天</span>
                              <span :class="isGoodsSelect === 'months'?'isActive': ''"
                                    @click="handleGoodsClick('months')">按月</span>
                              <span :class="isGoodsSelect === 'years'?'isActive': ''"
                                    @click="handleGoodsClick('years')">按年</span>
                            </div>
                            <a-range-picker valueFormat="YYYY-MM-DD" @change="goodsDateChange" size="large"/>
                          </div>

                          <a-table
                            :columns="columns"
                            :data-source="goodsList"
                            :loading="goodsLoading"
                            :scroll="{ y: 240 }"
                            :pagination="{
                              total: goodsTotal,
                              current: goodsParam.pageNum,
                              pageSize: 5,
                              showSizeChanger: false,
                              showQuickJumper: true,
                              showTotal: total => `共 ${total} 条`
                            }"
                          />
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>
                <div>
                  &lt;!&ndash; Add store rankings in a new row below &ndash;&gt;
                  <a-row :gutter="16" style="margin-top: 16px">
                    &lt;!&ndash; 店铺排行榜 &ndash;&gt;
                    <a-col :span="24">
                      <div class="rank-box">
                        <div class="rank-header" @click="toggleStoreRank" style="cursor: pointer">
                          <div class="rank-title">
                            店铺排行榜
                            <a-icon :type="storeRankExpanded ? 'up' : 'down'" />
                          </div>
                        </div>

                        &lt;!&ndash; 使用v-show控制内容显示/隐藏 &ndash;&gt;
                        <div v-show="storeRankExpanded">
                          <div class="filter-box">
                            <div class="btn-group">
                              <span :class="isStoreSelect === 'days'?'isActive': ''"
                                    @click="handleStoreClick('days')">按天</span>
                              <span :class="isStoreSelect === 'months'?'isActive': ''"
                                    @click="handleStoreClick('months')">按月</span>
                              <span :class="isStoreSelect === 'years'?'isActive': ''"
                                    @click="handleStoreClick('years')">按年</span>
                            </div>
                            <a-range-picker valueFormat="YYYY-MM-DD" @change="storeDateChange" size="large"/>
                          </div>

                          <a-table
                            :columns="storeColumns"
                            :data-source="storeList"
                            :loading="storeLoading"
                            :scroll="{ y: 240 }"
                            :pagination="{
                              total: storeTotal,
                              current: storeParam.pageNum,
                              pageSize: 5,
                              showSizeChanger: false,
                              showQuickJumper: true,
                              showTotal: total => `共 ${total} 条`
                            }"
                          />
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </div>-->
      </a-card>
    </template>


  </page-header-wrapper>
</template>

<script>
import {timeFix} from '@/utils/util'
import * as echarts from 'echarts'
import {mapGetters} from 'vuex'
import {PageHeaderWrapper} from '@/components/ProLayout'
import {getUserProfile} from '@/api/system/user'
import {
  goodsResult,
  homeResult,
  advertResult,
  goodsStatistics,
  storeStatistics,
  userConsumptionStatistics
} from '@/api/statistics/home'
import {orderAmountChart} from "@/api/statistics/order";
import {pageGoods} from "@/api/goods/goods";

export default {
  name: 'indexWelcomeAdmin',
  components: {
    PageHeaderWrapper
  },
  data() {
    return {
      dataList: {
        today: {},
        yesterday: {},
      }, //首页看板
      advert: {}, //广告
      goodsShare: {}, //商品
      timeFix: timeFix(),
      // 用户信息
      user: {
        dept: {
          deptName: ''
        }
      },

      orderAmountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime: undefined,

      },
      orderCountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime: undefined,

      },
      /*销售数据选择*/
      isOrderSelect: 'days',
      isOrderCountSelect: 'days',
      roleGroup: {},
      postGroup: {},
      isGoodsSelect: 'days',
      goodsParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime: undefined,
        pageSize: 5,
        pageNum: 1
      },
      goodsTotal: 0,
      columns: [
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          key: 'shopName',
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
        },
        {
          title: '商品销量',
          dataIndex: 'salesNum',
          key: 'salesNum',
          defaultSortOrder: 'descend'
        }
      ],
      goodsList: [],
      loading: false,
      storeColumns: [
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          key: 'shopName',
        },
        {
          title: '总销售额',
          dataIndex: 'shopAccount',
          key: 'shopAccount',
        }
      ],
      storeList: [],
      isStoreSelect: 'days',
      storeParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime: undefined,
        pageSize: 5,
        pageNum: 1
      },
      storeTotal: 0,
      storeLoading: false,
      isUserSelect: 'days',
      userParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime: undefined,
        pageSize: 5,
        pageNum: 1
      },
      userTotal: 0,
      userColumns: [
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          key: 'nickName',
        },
        {
          title: '消费总额',
          dataIndex: 'shopAccount',
          key: 'shopAccount',
        }
      ],
      userList: [],
      userLoading: false,
      // 添加展开/收起状态控制
      userRankExpanded: true,
      goodsRankExpanded: false,
      storeRankExpanded: false,
      currentExpandedRank: 'user', // 设置当前展开的是消费排行榜
    }
  },
  computed: {
    ...mapGetters([
      'avatar',
      'nickname'
    ])
  },
  created() {
    this.getUser()
    this.getHomeStatistics();
    this.getAdvertResult();
    this.getGoodResult();
    this.getUserList();
  },
  mounted() {
    this.drawOrderAmountChart()
    this.drawOrderCountChart()

  },
  methods: {
    getTotal(num) {
      return `共${num}条`
    },
    // 获取用户信息
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    getHomeStatistics() {
      homeResult().then(response => {
        this.dataList = response.data
      });

    },
    getAdvertResult() {
      advertResult().then(response => {
        this.advert = response.data
      });
    },
    getGoodResult() {
      goodsResult().then(response => {
        this.goodsShare = response.data
      });
    },

    orderAmountDateChange(val) {
      this.orderAmountParam.startTime = val[0] + ' 00:00:00';
      this.orderAmountParam.endTime = val[1] + ' 23:59:59';
      this.drawOrderAmountChart()
    },
    orderCountDateChange(val) {
      this.orderCountParam.startTime = val[0] + ' 00:00:00';
      this.orderCountParam.endTime = val[1] + ' 23:59:59';
      this.drawOrderCountChart()
    },
    handelOrderClick(val) {
      this.isOrderSelect = val;
      this.orderAmountParam.chartType = val;
      this.drawOrderAmountChart();
    },
    handelOrderCountClick(val) {
      this.isOrderCountSelect = val;
      this.orderCountParam.chartType = val;
      this.drawOrderCountChart();
    },

    /*drawOrderAmountChart() {
      // // 添加
      var chartDom = document.getElementById("orderAmountChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '订单销金额趋势图'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单销售额', '运费金额','退款金额']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '运费金额',
                type: 'line',
                stack: 'shippingAmount',
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(211,89,13,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(197,105,67,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#ee8e22' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#ee8e22' // 设置标点的颜色为蓝色
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.shippingPayAmountList
              },
              {
                name: '退款金额',
                type: 'line',
                stack: 'refundAmount',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(218,119,119,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(239,5,5,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundAmountList
              },
              {
                name: '订单销售额',
                type: 'line',
                stack: 'payAmount',
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  },
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.payAmountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

    drawOrderCountChart() {
      // // 添加
      var chartDom = document.getElementById("orderCountChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderCountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            title: {
              text: '订单量趋势图'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单量', '退款量']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '退款量',
                type: 'line',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      } else {
                        return params.value;
                      }
                    }
                  }
                },
                stack: 'refundCount',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(238,177,184,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(255,0,0,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundCountList
              },
              {
                name: '订单量',
                type: 'line',
                stack: 'orderCount',
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  show: true,
                  position: 'top'
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.orderCountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },*/

    onSponsorTabChange(key, type) {
      this[type] = key
    },

    handleGoodsClick(val) {
      this.isGoodsSelect = val;
      this.goodsParam.chartType = val;
      this.goodsParam.pageNum = 1;
      this.getGoodsList();
    },

    goodsDateChange(val) {
      this.goodsParam.startTime =  val[0] ? val[0] + ' 00:00:00' : '';
      this.goodsParam.endTime = val[1] ? val[1] + ' 23:59:59' : '';
      this.goodsParam.pageNum = 1;
      this.getGoodsList();
    },

    getGoodsList() {
      if (!this.goodsRankExpanded) return;

      this.goodsLoading = true;
      goodsStatistics(this.goodsParam).then((res) => {
        this.goodsList = res.data.records;
        this.goodsTotal = res.data.total;
        this.goodsLoading = false;
      }).finally(() => {
        this.goodsLoading = false;
      });
    },

    handleGoodsTableChange(page) {
      this.goodsParam.pageNum = page;
      this.getGoodsList();
    },

    handleStoreClick(val) {
      this.isStoreSelect = val;
      this.storeParam.chartType = val;
      this.storeParam.pageNum = 1;
      this.getStoreList();
    },

    storeDateChange(val) {
      this.storeParam.startTime =  val[0] ? val[0] + ' 00:00:00' : '';
      this.storeParam.endTime = val[1] ? val[1] + ' 23:59:59' : '';
      this.storeParam.pageNum = 1;
      this.getStoreList();
    },

    getStoreList() {
      if (!this.storeRankExpanded) return;

      this.storeLoading = true;
      storeStatistics(this.storeParam).then((res) => {
        this.storeList = res.data.records;
        this.storeTotal = res.data.total;
      }).finally(() => {
        this.storeLoading = false;
      });
    },

    handleStoreTableChange(page) {
      this.storeParam.pageNum = page;
      this.getStoreList();
    },

    // 消费排行相关方法
    handleUserClick(val) {
      this.isUserSelect = val;
      this.userParam.chartType = val;
      this.userParam.pageNum = 1;
      this.getUserList();
    },

    userDateChange(val) {
      this.userParam.startTime = val[0] ? val[0] + ' 00:00:00' : '';
      this.userParam.endTime = val[1] ? val[1] + ' 23:59:59' : '';
      this.userParam.pageNum = 1;
      this.getUserList();
    },

    getUserList() {
      if (!this.userRankExpanded) return;

      this.userLoading = true;
      userConsumptionStatistics(this.userParam).then((res) => {
        this.userList = res.data.records;
        this.userTotal = res.data.total;
      }).finally(() => {
        this.userLoading = false;
      });
    },

    handleUserTableChange(page) {
      this.userParam.pageNum = page;
      this.getUserList();
    },

    // 添加切换展开/收起的方法
    toggleUserRank() {
      if (this.currentExpandedRank === 'user') {
        // 如果当前已经展开，则收起
        this.userRankExpanded = false;
        this.currentExpandedRank = null;
      } else {
        // 收起其他排行榜
        this.goodsRankExpanded = false;
        this.storeRankExpanded = false;
        // 展开用户排行榜
        this.userRankExpanded = true;
        this.currentExpandedRank = 'user';
        // 首次展开时加载数据
        if (this.userList.length === 0) {
          this.getUserList();
        }
      }
    },
    toggleGoodsRank() {
      if (this.currentExpandedRank === 'goods') {
        // 如果当前已经展开，则收起
        this.goodsRankExpanded = false;
        this.currentExpandedRank = null;
      } else {
        // 收起其他排行榜
        this.userRankExpanded = false;
        this.storeRankExpanded = false;
        // 展开商品排行榜
        this.goodsRankExpanded = true;
        this.currentExpandedRank = 'goods';
        // 首次展开时加载数据
        if (this.goodsList.length === 0) {
          this.getGoodsList();
        }
      }
    },
    toggleStoreRank() {
      if (this.currentExpandedRank === 'store') {
        // 如果当前已经展开，则收起
        this.storeRankExpanded = false;
        this.currentExpandedRank = null;
      } else {
        // 收起其他排行榜
        this.userRankExpanded = false;
        this.goodsRankExpanded = false;
        // 展开店铺排行榜
        this.storeRankExpanded = true;
        this.currentExpandedRank = 'store';
        // 首次展开时加载数据
        if (this.storeList.length === 0) {
          this.getStoreList();
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "../index.less";

blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}

.project-list {

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 66px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .download {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.mobile {

  .project-list {

    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

.home {
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .col-item {
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  font-family: "open sans",
  "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;

  ul {
    list-style-type: none;
  }

  h4 {
    margin-top: 0px;
  }

  h2 {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 100;
  }

  p {
    margin-top: 10px;

    b {
      font-weight: 700;
    }
  }

  .update-log {
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.cleafix {
  clear: both;
  overflow: hidden;
  content: '';
}

::v-deep .el-icon-s-custom:before {
  font-size: 30px;
}

.cornflowerblue {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-right: 10px;
}

::v-deep .el-table th {
  background: rgba(3, 146, 240, 1);
  color: #fff;
}

::v-deep .el-table .warning-row {
  background: rgb(169, 109, 205, 0.1);
}

::v-deep .el-table .success-row {
  background: rgba(108, 182, 201, 0.1);
}

::v-deep .el-table__body-wrapper {
  height: 324px;
  overflow-y: auto;
}

.two1 {
  // margin-left: 125px;
  margin-bottom: 20px;

  span {
    display: inline-block;
    line-height: 40px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.two {
  margin-left: 10px;
  margin-right: 125px;

  span {
    display: inline-block;
    line-height: 40px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.isActive {
  background: #1890ff;
  color: #ffffff !important;
  border: 1px solid #1890ff !important;

  // color: #fff;
}

::v-deep .el-range-input {
  // width: 84px;
}

::v-deep .el-input__inner {
  // width: auto !important;
}

.title {
  font-weight: bold;
  font-size: 16px;
}

.color {
  color: red;
  font-size: 18px;
}

.margin-b-10 {
  margin-bottom: 10px !important;
}

.cleafix {
  clear: both;
  overflow: hidden;
  content: '';
}

// 固定表格高度样式
::v-deep .ant-table-wrapper {
  .ant-table-body {
    min-height: 240px; // 固定表格高度
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    padding: 8px;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
  }
}

.rank-container {
  background: #fff;
  border-radius: 2px;
}

.rank-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
}

.table-title {
  font-weight: bold;
  font-size: 16px;
  padding: 16px 0;
}

// 修改表格样式
::v-deep .ant-table-wrapper {
  .ant-table {
    border-radius: 0;
  }

  .ant-table-body {
    height: 270px !important; // 固定高度
    min-height: unset;
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    padding: 12px 8px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-tbody > tr > td {
    padding: 12px 8px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  // 调整分页器样式
  .ant-pagination {
    margin: 16px 0;
    padding: 0 24px;
  }
}

// 修改日期选择器样式
::v-deep .ant-picker {
  margin-left: 16px;
}

// 修改按钮组样式
.two {
  display: flex;
  align-items: center;

  span {
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    border: 1px solid #d9d9d9;
    margin: 0;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
}

// 移除多余的外边距
.ant-card {
  margin-bottom: 0 !important;
}

.ant-row {
  margin: 0 !important;
}

.rank-header {
  &:hover {
    background-color: #fafafa;
  }

  .rank-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>

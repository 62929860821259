<template>
  <a-modal
    :title="$t('通用.按钮.分账比例配置')"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :confirmLoading="confirmLoading"
    width="600px"
  >
    <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
      <a-form-item :label="$t('店铺分账比例')" prop="sratio">
        <a-input-number
          v-decorator="['sratio', { rules: [{ required: true, message: '请输入店铺分账比例' }] }]"
          :min="0"
          :max="100"
          style="width: 100%"
        />
      </a-form-item>
      
      <a-form-item :label="$t('运营总公司分账比例')" prop="hratio">
        <a-input-number
          v-decorator="['hratio', { rules: [{ required: true, message: '请输入运营总公司分账比例' }] }]"
          :min="0"
          :max="100"
          style="width: 100%"
        />
      </a-form-item>
      
      <a-form-item :label="$t('运营分公司分账比例')" prop="bratio">
        <a-input-number
          v-decorator="['bratio', { rules: [{ required: true, message: '请输入运营分公司分账比例' }] }]"
          :min="0"
          :max="100"
          style="width: 100%"
        />
      </a-form-item>
      
      <a-form-item :label="$t('平台分账比例')" prop="pratio">
        <a-input-number
          v-decorator="['pratio', { rules: [{ required: true, message: '请输入平台分账比例' }] }]"
          :min="0"
          :max="100"
          style="width: 100%"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { updateShopRatios } from '@/api/shop/shop'

export default {
  name: 'RatioConfigModal',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      currentRecord: null
    }
  },
  methods: {
    show(record) {
      this.visible = true
      this.currentRecord = record
      this.$nextTick(() => {
        this.form.setFieldsValue({
          sratio: record.sratio,
          hratio: record.hratio,
          bratio: record.bratio,
          pratio: record.pratio
        })
      })
    },
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          const total = values.sratio + values.hratio + values.bratio + values.pratio
          if (total !== 100) {
            this.$message.error('所有比例之和必须等于100')
            this.confirmLoading = false
            return
          }
          
          updateShopRatios({ 
            id: this.currentRecord.id,
            ...values 
          })
            .then(() => {
              this.$message.success('保存成功')
              this.visible = false
              this.$emit('ok')
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    }
  }
}
</script> 
import request from '@/utils/request'


// 查询商户认证列表
export function listCompany(query) {
  return request({
    url: '/company/company/list',
    method: 'get',
    params: query
  })
}

// 查询商户认证分页
export function pageCompany(query) {
  return request({
    url: '/company/company/page',
    method: 'get',
    params: query
  })
}

// 查询商户认证详细
export function getCompany(data) {
  return request({
    url: '/company/company/detail',
    method: 'get',
    params: data
  })
}

// 新增商户认证
export function addCompany(data) {
  return request({
    url: '/company/company/add',
    method: 'post',
    data: data
  })
}

// 修改商户认证
export function updateCompany(data) {
  return request({
    url: '/company/company/edit',
    method: 'post',
    data: data
  })
}

// 删除商户认证
export function delCompany(data) {
  return request({
    url: '/company/company/delete',
    method: 'post',
    data: data
  })
}
export function companyAudit (data) {
  return request({
    url: '/company/company/companyAudit',
    method: 'post',
    data: data
  })
}

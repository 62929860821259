<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
<!--      <a-form-model-item prop="userId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.用户ID')}}<template slot="title">{{$t('用户提现.用户ID')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('用户提现.用户ID')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="shopId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.店铺ID')}}<template slot="title">{{$t('用户提现.店铺ID')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('用户提现.店铺ID')" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop="money" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户提现.提现金额')}}<template slot="title">{{$t('用户提现.提现金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.money"  :placeholder="$t('通用.输入.请输入')+$t('用户提现.提现金额')" disabled="true"/>
      </a-form-model-item>


<!--      <a-form-model-item prop="status" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.0-未审核 1-审核未通过 2-审核已通过')}}<template slot="title">{{$t('用户提现.0-未审核 1-审核未通过 2-审核已通过')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="accountNum" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.提现账号')}}<template slot="title">{{$t('用户提现.提现账号')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.accountNum" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户提现.提现账号')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="accountName" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.提现账号姓名')}}<template slot="title">{{$t('用户提现.提现账号姓名')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.accountName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户提现.提现账号姓名')" />-->
<!--      </a-form-model-item>-->



















      <a-form-model-item prop="serviceChargeNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户提现.提现手续费')}}<template slot="title">{{$t('用户提现.提现手续费')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.serviceChargeNum"  :placeholder="$t('通用.输入.请输入')+$t('用户提现.提现手续费')" disabled="true"/>
      </a-form-model-item>


      <a-form-model-item prop="withdrawType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户提现.提现方式')}}<template slot="title">{{$t('用户提现.提现方式')}}</template>
          </a-tooltip>
        </span>
        <a-select :placeholder="$t('通用.输入.请选择')+$t('用户提现.提现方式')" v-model="form.withdrawType" allowClear disabled="true">
          <a-select-option  value="1">{{$t('用户提现.支付宝')}}</a-select-option>
          <a-select-option  value="2">{{$t('用户提现.微信')}}</a-select-option>
<!--          <a-select-option  value="3">{{$t('用户提现.银行卡')}}</a-select-option>-->
        </a-select>
      </a-form-model-item>




      <span v-if="form.withdrawType == '1' || form.withdrawType=='2'">
          <a-form-model-item prop="qrCode" >
            <span slot="label" >
              <a-tooltip>
                {{$t('用户提现.提现二维码')}}<template slot="title">{{$t('用户提现.提现二维码')}}</template>
              </a-tooltip>
            </span>
<!--            <a-input v-model="form.qrCode" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('用户提现.提现二维码')" disabled="true"/>-->

            <img :src="form.qrCode" width="150" height="150"/>

          </a-form-model-item>
          <a-form-model-item prop="name" >
            <span slot="label" >
              <a-tooltip>
                {{$t('用户提现.姓名')}}<template slot="title">{{$t('用户提现.姓名')}}</template>
              </a-tooltip>
            </span>
            <a-input v-model="form.name" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('用户提现.姓名')" disabled="true"/>
          </a-form-model-item>
      </span>



<!--      <span v-if="form.withdrawType == '3'">-->
<!--          <a-form-model-item prop="cardholderName" >-->
<!--            <span slot="label" >-->
<!--              <a-tooltip>-->
<!--                {{$t('用户提现.持卡人姓名')}}<template slot="title">{{$t('用户提现.持卡人姓名')}}</template>-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--            <a-input v-model="form.cardholderName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('用户提现.持卡人姓名')" disabled="true"/>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="bankName" >-->
<!--            <span slot="label" >-->
<!--              <a-tooltip>-->
<!--                {{$t('用户提现.银行名称')}}<template slot="title">{{$t('用户提现.银行名称')}}</template>-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--            <a-input v-model="form.bankName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('用户提现.银行名称')" disabled="true"/>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="cardNumber" >-->
<!--            <span slot="label" >-->
<!--              <a-tooltip>-->
<!--                {{$t('用户提现.卡号')}}<template slot="title">{{$t('用户提现.卡号')}}</template>-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--            <a-input v-model="form.cardNumber" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('用户提现.卡号')" disabled="true"/>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="openBank" >-->
<!--            <span slot="label" >-->
<!--              <a-tooltip>-->
<!--                {{$t('用户提现.开户行')}}<template slot="title">{{$t('用户提现.开户行')}}</template>-->
<!--              </a-tooltip>-->
<!--            </span>-->
<!--            <a-input v-model="form.openBank" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('用户提现.开户行')" disabled="true"/>-->
<!--          </a-form-model-item>-->

<!--    </span>-->




<!--      <a-form-model-item prop="withdrawUserType" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.1：普通用户提现 2：店铺提现4：直播提现5：承兑商提现')}}<template slot="title">{{$t('用户提现.1：普通用户提现 2：店铺提现4：直播提现5：承兑商提现')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="orderNo" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.第三方订单号(微信或支付的交易单号)')}}<template slot="title">{{$t('用户提现.第三方订单号(微信或支付的交易单号)')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.orderNo" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('用户提现.第三方订单号(微信或支付的交易单号)')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="failureReason" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.失败原因')}}<template slot="title">{{$t('用户提现.失败原因')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.failureReason" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('用户提现.失败原因')" />-->
<!--      </a-form-model-item>-->





      <a-form-model-item prop="status" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户提现.状态')}}<template slot="title">{{$t('用户提现.0-未审核 1-审核未通过 2-审核已通过')}}</template>
          </a-tooltip>
        </span>
        <a-select :placeholder="$t('通用.输入.请选择')+$t('用户提现.状态')" v-model="form.status" allowClear>
          <a-select-option  :value="0">{{$t('用户提现.未审核')}}</a-select-option>
          <a-select-option  :value="1">{{$t('用户提现.审核未通过')}}</a-select-option>
          <a-select-option  :value="2">{{$t('用户提现.审核已通过')}}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="auditOpinion" >
        <span slot="label" >
          <a-tooltip>
            {{$t('用户提现.审核意见')}}<template slot="title">{{$t('用户提现.审核意见')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.auditOpinion" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('用户提现.审核意见')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="remark" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('用户提现.备注')}}<template slot="title">{{$t('用户提现.备注')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->


      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWithdraw, addWithdraw, updateWithdraw } from '@/api/withdraw/withdraw'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag/index.vue";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        shopId: null,

        money: null,

        status: 0,

        accountNum: null,

        accountName: null,

        serviceChargeNum: null,

        withdrawType: null,

        withdrawUserType: null,

        orderNo: null,

        failureReason: null,

        auditOpinion: null,

        createTime: null,

        remark: null,

        qrCode: null,

        name: null,

        cardholderName: null,

        bankName: null,

        cardNumber: null,

        openBank: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        shopId: null,
        money: null,
        status: 0,
        accountNum: null,
        accountName: null,
        serviceChargeNum: null,
        withdrawType: null,
        withdrawUserType: null,
        orderNo: null,
        failureReason: null,
        auditOpinion: null,
        createTime: null,
        remark: null,
        qrCode: null,
        name: null,
        cardholderName: null,
        bankName: null,
        cardNumber: null,
        openBank: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWithdraw({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.按钮.审核')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateWithdraw(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addWithdraw(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<template>
  <a-drawer width="65%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
<!--      <a-form-model-item prop="requestNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.${comment}')}}<template slot="title">{{$t('商户认证.${comment}')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.requestNo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.${comment}')" />
      </a-form-model-item>
      <a-form-model-item prop="businessRole" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户业务角色')}}<template slot="title">{{$t('商户认证.商户业务角色')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.businessRole" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户业务角色')" />
      </a-form-model-item>
      <a-form-model-item prop="parentMerchantNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.上级商户编号')}}<template slot="title">{{$t('商户认证.上级商户编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.parentMerchantNo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.上级商户编号')" />
      </a-form-model-item>-->
      <a-form-model-item prop="licenceUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户证件照片地址')}}<template slot="title">{{$t('商户认证.商户证件照片地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.licenceUrl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户证件照片地址')" />
      </a-form-model-item>
      <a-form-model-item prop="signName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户签约名与商户证件主体名称一致')}}<template slot="title">{{$t('商户认证.商户签约名与商户证件主体名称一致')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.signName" :maxLength="255" placeholder="请输入商户签约名与商户证件主体名称一致" />
      </a-form-model-item>
      <a-form-model-item prop="signType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户签约类型')}}<template slot="title">{{$t('商户认证.商户签约类型')}}</template>
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.signType" button-style="solid">
          <a-radio-button v-for="(d, index) in signType" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item prop="licenceNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户证件号码')}}
            <template slot="title">{{$t('商户认证.商户证件号码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.licenceNo" :maxLength="255" placeholder="请输入商户证件号码:统一社会信用代码证编号、事业单位法人证书编号、社会团体证书编号等，与商户签约类型匹配" />
      </a-form-model-item>
      <a-form-model-item prop="shortName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户简称')}}<template slot="title">{{$t('商户认证.商户简称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shortName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户简称')" />
      </a-form-model-item>
      <a-form-model-item prop="legalName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.法人名称')}}<template slot="title">{{$t('商户认证.法人名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.legalName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.法人名称:请填写法人对应身份证件的姓名')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="legalLicenceType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.ID_CARD:法人证件类型，ID_CARD(法人身份证) FPRP(外国人永久居留身份证) PASSPORT(护照) HM_VISITORPASS(港澳居民往来内地通行证) TAIWAN(台胞证) SOLDIER(士兵证) OFFICERS(军官证)  不传默认身份证')}}<template slot="title">{{$t('商户认证.ID_CARD:法人证件类型，ID_CARD(法人身份证) FPRP(外国人永久居留身份证) PASSPORT(护照) HM_VISITORPASS(港澳居民往来内地通行证) TAIWAN(台胞证) SOLDIER(士兵证) OFFICERS(军官证)  不传默认身份证')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>-->
      <a-form-model-item prop="legalLicenceNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.法人证件号码')}}<template slot="title">{{$t('商户认证.法人证件号码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.legalLicenceNo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.法人证件号码')" />
      </a-form-model-item>
      <a-form-model-item prop="legalLicenceFrontUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.请上传带有人像面的法人证件照片')}}<template slot="title">{{$t('商户认证.请上传带有人像面的法人证件照片')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.legalLicenceFrontUrl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.请上传带有人像面的法人证件照片')" />
      </a-form-model-item>
      <a-form-model-item prop="legalLicenceBackUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.法人证件非人像面照片')}}<template slot="title">{{$t('商户认证.法人证件非人像面照片')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.legalLicenceBackUrl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.法人证件非人像面照片')" />
      </a-form-model-item>
      <a-form-model-item prop="mobile" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.手机号')}}<template slot="title">{{$t('商户认证.手机号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.mobile" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.手机号')" />
      </a-form-model-item>
      <a-form-model-item prop="contactName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户联系人名称')}}<template slot="title">{{$t('商户认证.商户联系人名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.contactName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户联系人名称')" />
      </a-form-model-item>
      <a-form-model-item prop="contactMobile" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户联系人手机号')}}<template slot="title">{{$t('商户认证.商户联系人手机号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.contactMobile" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户联系人手机号')" />
      </a-form-model-item>
      <a-form-model-item prop="contactEmail" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户联系人邮箱')}}<template slot="title">{{$t('商户认证.商户联系人邮箱')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.contactEmail" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户联系人邮箱')" />
      </a-form-model-item>
      <a-form-model-item prop="contactLicenceNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户联系人证件号')}}<template slot="title">{{$t('商户认证.商户联系人证件号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.contactLicenceNo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户联系人证件号')" />
      </a-form-model-item>
      <a-form-model-item prop="province" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.经营地址选择对应的省编号')}}<template slot="title">{{$t('商户认证.经营地址选择对应的省编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.province" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.经营地址选择对应的省编号')" />
      </a-form-model-item>
      <a-form-model-item prop="city" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.经营地址选择对应的市编号')}}<template slot="title">{{$t('商户认证.经营地址选择对应的市编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.city" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.经营地址选择对应的市编号')" />
      </a-form-model-item>
      <a-form-model-item prop="district" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.经营地址选择对应的区编号')}}<template slot="title">{{$t('商户认证.经营地址选择对应的区编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.district" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.经营地址选择对应的区编号')" />
      </a-form-model-item>
      <a-form-model-item prop="address" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.经营地址详细信息')}}<template slot="title">{{$t('商户认证.经营地址详细信息')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.address" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.经营地址详细信息')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="settlementDirection" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.开通结算产品时，必填，1、ACCOUNT(结算到支付账户)。2、BANKCARD(结算到银行账户)。对公账户/单位结算卡账户名称默认为商户签约名称；借记卡/存折账户名称默认为法人姓名')}}<template slot="title">{{$t('商户认证.开通结算产品时，必填，1、ACCOUNT(结算到支付账户)。2、BANKCARD(结算到银行账户)。对公账户/单位结算卡账户名称默认为商户签约名称；借记卡/存折账户名称默认为法人姓名')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.settlementDirection" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.开通结算产品时，必填，1、ACCOUNT(结算到支付账户)。2、BANKCARD(结算到银行账户)。对公账户/单位结算卡账户名称默认为商户签约名称；借记卡/存折账户名称默认为法人姓名')" />
      </a-form-model-item>-->
      <a-form-model-item prop="bankcode" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.银行账户开户总行编码')}}<template slot="title">{{$t('商户认证.银行账户开户总行编码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.bankcode" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.银行账户开户总行编码')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="bankAccountType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.银行账户类型，企业：对公账户/单位结算卡，账户名称默认为商户签约名称。个体户：对公账户/借记卡/存折/单位结算卡UNIT_SETTLEMENT_CARD(单位结算卡)ENTERPRISE_ACCOUNT(对公账户)DEBIT_CARD(借记卡)PASSBOOK(存折)')}}<template slot="title">{{$t('商户认证.银行账户类型，企业：对公账户/单位结算卡，账户名称默认为商户签约名称。个体户：对公账户/借记卡/存折/单位结算卡UNIT_SETTLEMENT_CARD(单位结算卡)ENTERPRISE_ACCOUNT(对公账户)DEBIT_CARD(借记卡)PASSBOOK(存折)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>-->
      <a-form-model-item prop="bankCardNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.银行账户号码')}}<template slot="title">{{$t('商户认证.银行账户号码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.bankCardNo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('商户认证.银行账户号码')" />
      </a-form-model-item>
      <a-form-model-item prop="authentication" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.认证状态')}}<template slot="title">{{$t('商户认证.认证状态')}}</template>
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.authentication" button-style="solid" disabled>
          <a-radio-button v-for="(d, index) in authentication" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item prop="ratio" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.分账比例')}}<template slot="title">{{$t('商户认证.分账比例')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.ratio"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.分账比例')" />
      </a-form-model-item>
      <a-form-model-item prop="merchantId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.商户号')}}<template slot="title">{{$t('商户认证.商户号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.merchantId"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.商户号')" />
      </a-form-model-item>
      <a-form-model-item prop="userAccount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.企业用户号')}}<template slot="title">{{$t('商户认证.企业用户号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userAccount"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.企业用户号')" />
      </a-form-model-item>
      <a-form-model-item prop="shopId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.店铺ID')}}<template slot="title">{{$t('商户认证.店铺ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.店铺ID')" />
      </a-form-model-item>
      <a-form-model-item prop="sysId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.系统用户ID')}}<template slot="title">{{$t('商户认证.系统用户ID')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sysId"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.系统用户ID')" />
      </a-form-model-item>
      <a-form-model-item prop="operate" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.运营公司id')}}<template slot="title">{{$t('商户认证.运营公司id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.operate"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.运营公司id')" />
      </a-form-model-item>
      <a-form-model-item prop="operateOne" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.运营公司上级运营公司')}}<template slot="title">{{$t('商户认证.运营公司上级运营公司')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.operateOne"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.运营公司上级运营公司')" />
      </a-form-model-item>
      <a-form-model-item prop="operateTwo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.运营公司上级运营公司上级运行总公司')}}<template slot="title">{{$t('商户认证.运营公司上级运营公司上级运行总公司')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.operateTwo"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.运营公司上级运营公司上级运行总公司')" />
      </a-form-model-item>
      <a-form-model-item prop="operateThree" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.运营公司上级运营公司上级运行总公司总平台')}}<template slot="title">{{$t('商户认证.运营公司上级运营公司上级运行总公司总平台')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.operateThree"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.运营公司上级运营公司上级运行总公司总平台')" />
      </a-form-model-item>
      <a-form-model-item prop="userId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.店主id,外键 (user.id)')}}<template slot="title">{{$t('商户认证.店主id,外键 (user.id)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.店主id,外键 (user.id)')" />
      </a-form-model-item>
      <a-form-model-item prop="extend" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展')}}<template slot="title">{{$t('商户认证.扩展')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extend"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展')" />
      </a-form-model-item>
      <a-form-model-item prop="extendOne" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展1')}}<template slot="title">{{$t('商户认证.扩展1')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extendOne"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展1')" />
      </a-form-model-item>
      <a-form-model-item prop="extendTwo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展2')}}<template slot="title">{{$t('商户认证.扩展2')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extendTwo"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展2')" />
      </a-form-model-item>
      <a-form-model-item prop="extendThree" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展3')}}<template slot="title">{{$t('商户认证.扩展3')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extendThree"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展3')" />
      </a-form-model-item>
      <a-form-model-item prop="extendFour" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展4')}}<template slot="title">{{$t('商户认证.扩展4')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extendFour"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展4')" />
      </a-form-model-item>
      <a-form-model-item prop="extendFive" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展5')}}<template slot="title">{{$t('商户认证.扩展5')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extendFive"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展5')" />
      </a-form-model-item>
      <a-form-model-item prop="extendSix" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.扩展6')}}<template slot="title">{{$t('商户认证.扩展6')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.extendSix"  :placeholder="$t('通用.输入.请输入')+$t('商户认证.扩展6')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('商户认证.备注')}}<template slot="title">{{$t('商户认证.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCompany, addCompany, updateCompany } from '@/api/company/company'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      signType:[
        {label:"个体工商户",value:"INDIVIDUAL"},
        {label:"企业",value:"ENTERPRISE"}
      ],
      authentication:[
        {label:"待认证",value:0},
        {label:"认证审核中",value:1},
        {label:"认证通过",value:2}
      ],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        requestNo: null,

        businessRole: null,

        parentMerchantNo: null,

        licenceUrl: null,

        signName: null,

        signType: null,

        licenceNo: null,

        shortName: null,

        legalName: null,

        legalLicenceType: null,

        legalLicenceNo: null,

        legalLicenceFrontUrl: null,

        legalLicenceBackUrl: null,

        mobile: null,

        contactName: null,

        contactMobile: null,

        contactEmail: null,

        contactLicenceNo: null,

        province: null,

        city: null,

        district: null,

        address: null,

        settlementDirection: null,

        bankcode: null,

        bankAccountType: null,

        bankCardNo: null,

        authentication: null,

        ratio: null,

        merchantId: null,

        userAccount: null,

        shopId: null,

        sysId: null,

        operate: null,

        operateOne: null,

        operateTwo: null,

        operateThree: null,

        userId: null,

        extend: null,

        extendOne: null,

        extendTwo: null,

        extendThree: null,

        extendFour: null,

        extendFive: null,

        extendSix: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        /*requestNo: [
          { required: true, message: this.$t('商户认证.${comment}')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],*/
        businessRole: [
          { required: true, message: this.$t('商户认证.商户业务角色')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        /*parentMerchantNo: [
          { required: true, message: this.$t('商户认证.上级商户编号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],*/
        licenceUrl: [
          { required: true, message: this.$t('商户认证.商户证件照片地址')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        signName: [
          { required: true, message: '商户签约名与商户证件主体名称一致'+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        signType: [
          { required: true, message: this.$t('商户认证.商户签约类型')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        licenceNo: [
          { required: true, message: this.$t('商户证件号码:统一社会信用代码证编号、事业单位法人证书编号、社会团体证书编号等，与商户签约类型匹配')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shortName: [
          { required: true, message: this.$t('商户认证.商户简称:将在收银台页面或者支付完成页向买家展示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        legalName: [
          { required: true, message: this.$t('商户认证.法人名称:请填写法人对应身份证件的姓名')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        /*legalLicenceType: [
          { required: true, message: this.$t('商户认证.ID_CARD:法人证件类型，ID_CARD(法人身份证) FPRP(外国人永久居留身份证) PASSPORT(护照) HM_VISITORPASS(港澳居民往来内地通行证) TAIWAN(台胞证) SOLDIER(士兵证) OFFICERS(军官证)  不传默认身份证')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],*/
        legalLicenceNo: [
          { required: true, message: this.$t('商户认证.法人证件号码，请填写法人对应身份证件号码')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        legalLicenceFrontUrl: [
          { required: true, message: this.$t('商户认证.请上传带有人像面的法人证件照片')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        legalLicenceBackUrl: [
          { required: true, message: this.$t('商户认证.法人证件非人像面照片')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('商户认证.手机号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: this.$t('商户认证.商户联系人名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        contactMobile: [
          { required: true, message: this.$t('商户认证.商户联系人手机号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        contactEmail: [
          { required: true, message: this.$t('商户认证.商户联系人邮箱')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        contactLicenceNo: [
          { required: true, message: this.$t('商户认证.商户联系人证件号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        province: [
          { required: true, message: this.$t('商户认证.要求按照商户实际经营地址选择对应的省编号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        city: [
          { required: true, message: this.$t('商户认证.要求按照商户实际经营地址选择对应的市编号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        district: [
          { required: true, message: this.$t('商户认证.要求按照商户实际经营地址选择对应的区编号')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        address: [
          { required: true, message: this.$t('商户认证.经营地址详细信息')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        /*settlementDirection: [
          { required: true, message: this.$t('商户认证.开通结算产品时，必填，1、ACCOUNT(结算到支付账户)。2、BANKCARD(结算到银行账户)。对公账户/单位结算卡账户名称默认为商户签约名称；借记卡/存折账户名称默认为法人姓名')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],*/
        bankcode: [
          { required: true, message: this.$t('商户认证.银行账户开户总行编码')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
       /* bankAccountType: [
          { required: true, message: this.$t('商户认证.银行账户类型，企业：对公账户/单位结算卡，账户名称默认为商户签约名称。个体户：对公账户/借记卡/存折/单位结算卡UNIT_SETTLEMENT_CARD(单位结算卡)ENTERPRISE_ACCOUNT(对公账户)DEBIT_CARD(借记卡)PASSBOOK(存折)')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],*/
        bankCardNo: [
          { required: true, message: this.$t('商户认证.银行账户号码')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        authentication: [
          { required: true, message: this.$t('商户认证.认证状态')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
        /*operate: [
          { required: true, message: this.$t('商户认证.运营公司id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        operateOne: [
          { required: true, message: this.$t('商户认证.运营公司上级运营公司')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        operateTwo: [
          { required: true, message: this.$t('商户认证.运营公司上级运营公司上级运行总公司')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        operateThree: [
          { required: true, message: this.$t('商户认证.运营公司上级运营公司上级运行总公司总平台')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        userId: [
          { required: true, message: this.$t('商户认证.店主id,外键 (user.id)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extend: [
          { required: true, message: this.$t('商户认证.扩展')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extendOne: [
          { required: true, message: this.$t('商户认证.扩展1')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extendTwo: [
          { required: true, message: this.$t('商户认证.扩展2')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extendThree: [
          { required: true, message: this.$t('商户认证.扩展3')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extendFour: [
          { required: true, message: this.$t('商户认证.扩展4')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extendFive: [
          { required: true, message: this.$t('商户认证.扩展5')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        extendSix: [
          { required: true, message: this.$t('商户认证.扩展6')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]*/
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        requestNo: null,
        businessRole: null,
        parentMerchantNo: null,
        licenceUrl: null,
        signName: null,
        signType: null,
        licenceNo: null,
        shortName: null,
        legalName: null,
        legalLicenceType: null,
        legalLicenceNo: null,
        legalLicenceFrontUrl: null,
        legalLicenceBackUrl: null,
        mobile: null,
        contactName: null,
        contactMobile: null,
        contactEmail: null,
        contactLicenceNo: null,
        province: null,
        city: null,
        district: null,
        address: null,
        settlementDirection: null,
        bankcode: null,
        bankAccountType: null,
        bankCardNo: null,
        authentication: null,
        ratio: null,
        merchantId: null,
        userAccount: null,
        shopId: null,
        sysId: null,
        operate: null,
        operateOne: null,
        operateTwo: null,
        operateThree: null,
        userId: null,
        extend: null,
        extendOne: null,
        extendTwo: null,
        extendThree: null,
        extendFour: null,
        extendFive: null,
        extendSix: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCompany({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateCompany(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addCompany(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

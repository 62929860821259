<template>
  <a-drawer width="45%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item :label="$t('广告.广告名称')" prop="name" >
        <a-input v-model="form.name" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('广告.广告名称')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('通用.文本.排序')" prop="sort" >
        <a-input-number v-model="form.sort" :maxLength="6" style="width: 100%"  @change="limitSort" :placeholder="$t('通用.输入.请输入')+$t('通用.文本.排序')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('广告.是否隐藏')" prop="isHidden" >
        <a-radio-group v-model="form.isHidden">
          <a-radio v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('广告.封面图')" prop="coverImg" >
        <a-upload
          name="coverImg"
          listType="picture-card"
          :multiple="false"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="coverImgUpload">
          <img
            v-if="form.coverImg"
            :src="form.coverImg"
            alt="picture"
            style="height: 102px; width: 102px;"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
          </div>
        </a-upload>
        <span>推荐尺寸 {{getBannerSize()}}</span>
      </a-form-model-item>
      <a-form-model-item :label="$t('通用.文本.选择商品')" prop="businessId">
        <a-button type="dashed" @click="openProdSelect" danger>
          <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
        </a-button>
      </a-form-model-item>
      <!-- 数据展示 -->
      <a-table
               :loading="goodsLoading"
               :scroll="{ x: '160%' }"
               rowKey="id"
               :size="tableSize"
               :columns="goodsColumns"
               :data-source="form.goodsList"
               :pagination="false"
               :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>

        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
        </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
      </a-table>

      <!--  活动    -->
      <a-table v-if="form.type===4"
               :loading="atvLoading"
               rowKey="id"
               :size="tableSize"
               :columns="activityColumns"
               :data-source="form.activityList"
               :pagination="false"
               :bordered="tableBordered">
        <span slot="activityType" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ActivityTypeEnum" :value="record.activityType"/>
        </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ActivitySaleStatusEnum" :value="record.status"/>
        </span>
        <template slot="coverPic" slot-scope="text, record">
          <div>
            <img v-if="record.coverPic" :src="record.coverPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.coverPic)" alt="none"/>
          </div>
        </template>
        <template slot="posterPic" slot-scope="text, record">
          <div>
            <img v-if="record.posterPic" :src="record.posterPic"  style="width:60px;height:62px;" @click="handleRecordPreview(record.posterPic)" alt="none"/>
          </div>
        </template>
      </a-table>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
      <img alt="example" style="width: 100%" :src="previewUrl" />
    </a-modal>
    <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
    <radio-apply-select-form ref="RadioApplySelectForm" @applySelect="indexApplyModalSelect"></radio-apply-select-form>
    <radio-activity-select-form ref="RadioActivitySelectForm" @atvSelect="indexActivityModalSelect"></radio-activity-select-form>
  </a-drawer>
</template>

<script>
import { getBanner, addBanner, updateBanner,bannerPosition } from '@/api/shop/banner'
import {mapGetters} from 'vuex'
import RadioApplySelectForm from "@/views/advert/apply/modules/RadioSelectForm";
import CustomDictTag from "@/components/DictCustomTag";
import RadioGoodsSelectForm from "@/views/goods/goods/modules/RadioSelectForm";
import RadioActivitySelectForm from "@/views/activity/activity/modules/ActivitySelectForm";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import Editor from '@/components/Editor'
import {listCategory} from "@/api/goods/category";
import {tableMixin} from '@/store/table-mixin'
import {allBrand} from "@/api/goods/brand";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    RadioGoodsSelectForm,
    RadioApplySelectForm,
    RadioActivitySelectForm,
    Editor
  },
  mixins: [tableMixin],
  data () {
    return {
      disable:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      goodsLoading: false,
      atvLoading: false,

      cateOptions: [],
      bannerPositionOptions: [],

      activityColumns: [
        {
          title: this.$t('活动记录.活动编号'),
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: this.$t('活动记录.活动类型'),
          dataIndex: 'activityType',
          scopedSlots: { customRender: 'activityType' },
          align: 'center'
        },
        {
          title: this.$t('活动记录.活动名称'),
          dataIndex: 'activityName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.开始时间'),
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.结束时间'),
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('活动记录.活动封面图'),
          dataIndex: 'coverPic',
          scopedSlots: {customRender: 'coverPic'},
          align: 'center'
        },
        {
          title: this.$t('活动记录.状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: this.$t('活动记录.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
      ],
      goodsColumns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'id',
          width: 150,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 200,
          fixed: 'left',
        },
        /*{
          title: this.$t('商品.包装单位'),
          width: 60,
          dataIndex: 'unit',
        },*/
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        /*{
          title: this.$t('商品.售卖开始时间'),
          width: 150,
          dataIndex: 'saleStartTime',
        },
        {
          title: this.$t('商品.售卖结束时间'),
          width: 150,
          dataIndex: 'saleEndTime',
        },*/
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
        {
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          width: 60,
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'createTime',
          width: 150,
          scopedSlots: {customRender: 'createTime'},
        },
      ],

      // 表单参数
      form: {
        id: null,
        goodsList: [],
        activityList: [],

        position: undefined,
        type: undefined,
        name: null,
        businessId: null,
        sort: null,
        content: null,
        isHidden: null,
        showTimes: null,
        coverImg: null,
        detailImg: null,
        startTime: null,
        endTime: null,
        userApplyId: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        position: [
          { required: true, message: this.$t('广告.位置')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('广告.广告名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        content: [
          { required: true, message: this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        /*businessId: [
          { required: true, message: this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],*/
        startTime: [
          { required: true, message: this.$t('广告.开始时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        endTime: [
          { required: true, message: this.$t('广告.结束时间')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        sort: [
          { required: true, message: this.$t('通用.文本.排序')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isHidden: [
          { required: true, message: this.$t('广告.是否隐藏')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        coverImg: [
          { required: true, message: this.$t('广告.封面图')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        cateId: [

        ],
        showTimes: [
          { required: true, message: this.$t('广告.展示时长')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
    this.getBrandList();
    this.getCateList();
    this.getBannerPosition();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    limitSort(sort) {
      let val = sort;
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.sort = val
    },
    limitShowTimes(sort) {
      let val = sort;
      if(val==null||val==undefined||val==''){
        val=0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.showTimes = val
    },


    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    indexGoodsModalSelect(records) {
      this.form.businessId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.businessId]);
    },

    openAtvSelect() {
      this.$refs.RadioActivitySelectForm.openAtv( [this.form.businessId]);
    },


    indexApplyModalSelect(records) {
      this.form.userApplyId = records[0].id
      this.form.startTime = records[0].startTime;
      this.form.endTime = records[0].endTime;
      this.form.name = records[0].advertName;

    },
    indexActivityModalSelect(records) {
      this.form.businessId = records[0].id
      this.form.activityList = records
      console.log('活动选择了',this.form.activityList)
    },

    openApplySelect() {
      this.$refs.RadioApplySelectForm.openApply([this.form.businessId]);
    },


    typeChange() {
      console.log('type改变为',this.form.type)
      if (this.form.type === 6) {
        this.form.businessId='/pages/user/activity/activity-total/activity-total'
      }
      if (this.form.type === 7) {
        this.form.businessId='https://h5.xinghejimei.com/pages/user/activity/activity-list/activity-list'
      }
    },


    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'banner'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'coverImg', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    coverDetailImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'banner'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'detailImg', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束

    // 表单重置
    reset () {
      this.disable=false
      this.formType = 1
      this.form = {
        id: null,
        position: undefined,
        type: undefined,
        name: null,
        goodsList: [],
        activityList :[],
        businessId: "0",
        sort: null,
        content: null,
        isHidden: null,
        showTimes: null,
        coverImg: null,
        detailImg: null,
        startTime: null,
        endTime: null,
        userApplyId: null,
        remark: null,
      }
    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBannerPosition() {
      bannerPosition().then(response => {
        this.bannerPositionOptions = response.data
      })
    },
    getBannerSize() {
      if (this.bannerPositionOptions.length > 0) {
        if (this.form.position) {
          return this.bannerPositionOptions.find(e => e.type === this.form.position).size;
        } else {
          return this.bannerPositionOptions[0].size;
        }
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.form.isHidden =false
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      this.disable = true;
      const id = row ? row.id : ids
      getBanner({"id":id}).then(response => {
        this.form = response.data
        this.open = true;
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateBanner(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addBanner(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

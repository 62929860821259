<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="source" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单来源 1：Android，2：IOS，3：PC，4：小程序，5：h5')}}<template slot="title">{{$t('收银订单.订单来源 1：Android，2：IOS，3：PC，4：小程序，5：h5')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.source"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单来源 1：Android，2：IOS，3：PC，4：小程序，5：h5')" />
      </a-form-model-item>
      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单类型,20:普通订单,50:退款订单')}}<template slot="title">{{$t('收银订单.订单类型,20:普通订单,50:退款订单')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="parentId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.父订单id,order_order.id')}}<template slot="title">{{$t('收银订单.父订单id,order_order.id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.parentId"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.父订单id,order_order.id')" />
      </a-form-model-item>
      <a-form-model-item prop="outTradeNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.三方支付订单号')}}<template slot="title">{{$t('收银订单.三方支付订单号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.outTradeNo" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('收银订单.三方支付订单号')" />
      </a-form-model-item>
      <a-form-model-item prop="shopId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.卖方客户id')}}<template slot="title">{{$t('收银订单.卖方客户id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.卖方客户id')" />
      </a-form-model-item>
      <a-form-model-item prop="shopName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.卖方客户名字')}}<template slot="title">{{$t('收银订单.卖方客户名字')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopName" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('收银订单.卖方客户名字')" />
      </a-form-model-item>
      <a-form-model-item prop="buyerId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.买方客户id')}}<template slot="title">{{$t('收银订单.买方客户id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.buyerId"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.买方客户id')" />
      </a-form-model-item>
      <a-form-model-item prop="buyerName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.买方客户名称')}}<template slot="title">{{$t('收银订单.买方客户名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.buyerName" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('收银订单.买方客户名称')" />
      </a-form-model-item>
      <a-form-model-item prop="userMessage" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.用户备注')}}<template slot="title">{{$t('收银订单.用户备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userMessage" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="addressDetail" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单收货信息JSON')}}<template slot="title">{{$t('收银订单.订单收货信息JSON')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="activityId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.参与活动的id ,可以是多个,用逗号分隔,这个')}}<template slot="title">{{$t('收银订单.参与活动的id ,可以是多个,用逗号分隔,这个')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.activityId" :maxLength="128" :placeholder="$t('通用.输入.请输入')+$t('收银订单.参与活动的id ,可以是多个,用逗号分隔,这个')" />
      </a-form-model-item>
      <a-form-model-item prop="activityDetail" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.活动信息json 根据项目来决定是数组还是单个活动')}}<template slot="title">{{$t('收银订单.活动信息json 根据项目来决定是数组还是单个活动')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="orderUserId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.下单客户id，标识是否代客下单')}}<template slot="title">{{$t('收银订单.下单客户id，标识是否代客下单')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.orderUserId"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.下单客户id，标识是否代客下单')" />
      </a-form-model-item>
      <a-form-model-item prop="payType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.支付方式 1,支付宝 2微信,3银行卡')}}<template slot="title">{{$t('收银订单.支付方式 1,支付宝 2微信,3银行卡')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="payTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.支付时间')}}<template slot="title">{{$t('收银订单.支付时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="orderStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单状态')}}<template slot="title">{{$t('收银订单.订单状态')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="refundStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单退款状态0:未申请 1.买家申请 2.卖家接受 3.买家发货 4.卖家收货 5.退款成功 6卖家拒绝')}}<template slot="title">{{$t('收银订单.订单退款状态0:未申请 1.买家申请 2.卖家接受 3.买家发货 4.卖家收货 5.退款成功 6卖家拒绝')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="goodsCount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.商品数量')}}<template slot="title">{{$t('收银订单.商品数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsCount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.商品数量')" />
      </a-form-model-item>
      <a-form-model-item prop="orderAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.总订单价格(原价)=商品金额+运费+包装费')}}<template slot="title">{{$t('收银订单.总订单价格(原价)=商品金额+运费+包装费')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.orderAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.总订单价格(原价)=商品金额+运费+包装费')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.商品金额')}}<template slot="title">{{$t('收银订单.商品金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.商品金额')" />
      </a-form-model-item>
      <a-form-model-item prop="payAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.总订单价格(实付)  =商品金额+运费(实付)+包装费-各种优惠的金额')}}<template slot="title">{{$t('收银订单.总订单价格(实付)  =商品金额+运费(实付)+包装费-各种优惠的金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.payAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.总订单价格(实付)  =商品金额+运费(实付)+包装费-各种优惠的金额')" />
      </a-form-model-item>
      <a-form-model-item prop="packingAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单包装费')}}<template slot="title">{{$t('收银订单.订单包装费')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.packingAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单包装费')" />
      </a-form-model-item>
      <a-form-model-item prop="shippingPayAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单运费(实付)')}}<template slot="title">{{$t('收银订单.订单运费(实付)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shippingPayAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单运费(实付)')" />
      </a-form-model-item>
      <a-form-model-item prop="shippingAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单运费(原价)')}}<template slot="title">{{$t('收银订单.订单运费(原价)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shippingAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单运费(原价)')" />
      </a-form-model-item>
      <a-form-model-item prop="balanceAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.余额支付金额')}}<template slot="title">{{$t('收银订单.余额支付金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.balanceAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.余额支付金额')" />
      </a-form-model-item>
      <a-form-model-item prop="outTradeAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.三方支付金额')}}<template slot="title">{{$t('收银订单.三方支付金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.outTradeAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.三方支付金额')" />
      </a-form-model-item>
      <a-form-model-item prop="preDepositAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.预售定金金额')}}<template slot="title">{{$t('收银订单.预售定金金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.preDepositAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.预售定金金额')" />
      </a-form-model-item>
      <a-form-model-item prop="preEndAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.预售尾款金额')}}<template slot="title">{{$t('收银订单.预售尾款金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.preEndAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.预售尾款金额')" />
      </a-form-model-item>
      <a-form-model-item prop="preProcess" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.预售订单进度')}}<template slot="title">{{$t('收银订单.预售订单进度')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.preProcess"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.预售订单进度')" />
      </a-form-model-item>
      <a-form-model-item prop="pointDeductionNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.积分抵扣数量')}}<template slot="title">{{$t('收银订单.积分抵扣数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pointDeductionNum"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.积分抵扣数量')" />
      </a-form-model-item>
      <a-form-model-item prop="pointDeductionAmount" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.积分抵扣金额')}}<template slot="title">{{$t('收银订单.积分抵扣金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pointDeductionAmount"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.积分抵扣金额')" />
      </a-form-model-item>
      <a-form-model-item prop="autoOrdersTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.自动接单时间(分钟)')}}<template slot="title">{{$t('收银订单.自动接单时间(分钟)')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.autoOrdersTime"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.自动接单时间(分钟)')" />
      </a-form-model-item>
      <a-form-model-item prop="isFirstOrder" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.是否首单 0:否 1:是')}}<template slot="title">{{$t('收银订单.是否首单 0:否 1:是')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isFirstOrder"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.是否首单 0:否 1:是')" />
      </a-form-model-item>
      <a-form-model-item prop="isRemind" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.顾客是否催发货 0-否 1-是')}}<template slot="title">{{$t('收银订单.顾客是否催发货 0-否 1-是')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isRemind"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.顾客是否催发货 0-否 1-是')" />
      </a-form-model-item>
      <a-form-model-item prop="isInvoice" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.是否开屏')}}<template slot="title">{{$t('收银订单.是否开屏')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isInvoice"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.是否开屏')" />
      </a-form-model-item>
      <a-form-model-item prop="isSplit" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.是否拆单')}}<template slot="title">{{$t('收银订单.是否拆单')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isSplit"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.是否拆单')" />
      </a-form-model-item>
      <a-form-model-item prop="isDisplay" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.显示标记，1：显示，0：不显示，默认1显示')}}<template slot="title">{{$t('收银订单.显示标记，1：显示，0：不显示，默认1显示')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.isDisplay"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.显示标记，1：显示，0：不显示，默认1显示')" />
      </a-form-model-item>
      <a-form-model-item prop="adminRemark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.后台备注')}}<template slot="title">{{$t('收银订单.后台备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.adminRemark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="startTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.线下订单预约开始时间')}}<template slot="title">{{$t('收银订单.线下订单预约开始时间')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.startTime" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('收银订单.线下订单预约开始时间')" />
      </a-form-model-item>
      <a-form-model-item prop="endTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.线下订单预约结束时间')}}<template slot="title">{{$t('收银订单.线下订单预约结束时间')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.endTime" :maxLength="64" :placeholder="$t('通用.输入.请输入')+$t('收银订单.线下订单预约结束时间')" />
      </a-form-model-item>
      <a-form-model-item prop="reservation" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.是否需要预约 0：否 1：是')}}<template slot="title">{{$t('收银订单.是否需要预约 0：否 1：是')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.reservation"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.是否需要预约 0：否 1：是')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.商品类型1.普通商品,2 线下商品')}}<template slot="title">{{$t('收银订单.商品类型1.普通商品,2 线下商品')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="dateTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.预约日期')}}<template slot="title">{{$t('收银订单.预约日期')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.dateTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="code" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.核销码')}}<template slot="title">{{$t('收银订单.核销码')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.code" :maxLength="6" :placeholder="$t('通用.输入.请输入')+$t('收银订单.核销码')" />
      </a-form-model-item>
      <a-form-model-item prop="orderNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单编号')}}<template slot="title">{{$t('收银订单.订单编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.orderNo"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单编号')" />
      </a-form-model-item>
      <a-form-model-item prop="shopLogo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.店铺logo')}}<template slot="title">{{$t('收银订单.店铺logo')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shopLogo" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('收银订单.店铺logo')" />
      </a-form-model-item>
      <a-form-model-item prop="couponMoney" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单代金券支付金额')}}<template slot="title">{{$t('收银订单.订单代金券支付金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.couponMoney"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单代金券支付金额')" />
      </a-form-model-item>
      <a-form-model-item prop="couponId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.订单代金券id')}}<template slot="title">{{$t('收银订单.订单代金券id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.couponId"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.订单代金券id')" />
      </a-form-model-item>
      <a-form-model-item prop="platformMoney" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.此单平台抽成')}}<template slot="title">{{$t('收银订单.此单平台抽成')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.platformMoney"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.此单平台抽成')" />
      </a-form-model-item>
      <a-form-model-item prop="lastRefundTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.最后退款时间')}}<template slot="title">{{$t('收银订单.最后退款时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.lastRefundTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="userLiveMoney" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.主播分佣')}}<template slot="title">{{$t('收银订单.主播分佣')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userLiveMoney" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('收银订单.主播分佣')" />
      </a-form-model-item>
      <a-form-model-item prop="liveId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.直播间id')}}<template slot="title">{{$t('收银订单.直播间id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.liveId"  :placeholder="$t('通用.输入.请输入')+$t('收银订单.直播间id')" />
      </a-form-model-item>
      <a-form-model-item prop="buyerMessage" >
        <span slot="label" >
          <a-tooltip>
            {{$t('收银订单.买家附言')}}<template slot="title">{{$t('收银订单.买家附言')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.buyerMessage" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('收银订单.买家附言')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCashier, addCashier, updateCashier } from '@/api/order/cashier'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        source: null,

        type: null,

        parentId: null,

        outTradeNo: null,

        shopId: null,

        shopName: null,

        buyerId: null,

        buyerName: null,

        userMessage: null,

        addressDetail: null,

        activityId: null,

        activityDetail: null,

        orderUserId: null,

        payType: null,

        payTime: null,

        orderStatus: 0,

        refundStatus: 0,

        goodsCount: null,

        orderAmount: null,

        goodsAmount: null,

        payAmount: null,

        packingAmount: null,

        shippingPayAmount: null,

        shippingAmount: null,

        balanceAmount: null,

        outTradeAmount: null,

        preDepositAmount: null,

        preEndAmount: null,

        preProcess: null,

        pointDeductionNum: null,

        pointDeductionAmount: null,

        autoOrdersTime: null,

        isFirstOrder: null,

        isRemind: null,

        isInvoice: null,

        isSplit: null,

        isDisplay: null,

        createTime: null,

        adminRemark: null,

        startTime: null,

        endTime: null,

        reservation: null,

        goodsType: null,

        dateTime: null,

        code: null,

        orderNo: null,

        shopLogo: null,

        couponMoney: null,

        couponId: null,

        platformMoney: null,

        lastRefundTime: null,

        userLiveMoney: null,

        liveId: null,

        buyerMessage: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        source: [
          { required: true, message: this.$t('收银订单.订单来源 1：Android，2：IOS，3：PC，4：小程序，5：h5')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        type: [
          { required: true, message: this.$t('收银订单.订单类型,20:普通订单,50:退款订单')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        parentId: [
          { required: true, message: this.$t('收银订单.父订单id,order_order.id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shopId: [
          { required: true, message: this.$t('收银订单.卖方客户id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shopName: [
          { required: true, message: this.$t('收银订单.卖方客户名字')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        buyerId: [
          { required: true, message: this.$t('收银订单.买方客户id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        buyerName: [
          { required: true, message: this.$t('收银订单.买方客户名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderUserId: [
          { required: true, message: this.$t('收银订单.下单客户id，标识是否代客下单')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderStatus: [
          { required: true, message: this.$t('收银订单.订单状态')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        goodsCount: [
          { required: true, message: this.$t('收银订单.商品数量')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        orderAmount: [
          { required: true, message: this.$t('收银订单.总订单价格(原价)=商品金额+运费+包装费')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        goodsAmount: [
          { required: true, message: this.$t('收银订单.商品金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        payAmount: [
          { required: true, message: this.$t('收银订单.总订单价格(实付)  =商品金额+运费(实付)+包装费-各种优惠的金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        packingAmount: [
          { required: true, message: this.$t('收银订单.订单包装费')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        shippingPayAmount: [
          { required: true, message: this.$t('收银订单.订单运费(实付)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        balanceAmount: [
          { required: true, message: this.$t('收银订单.余额支付金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        outTradeAmount: [
          { required: true, message: this.$t('收银订单.三方支付金额')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        autoOrdersTime: [
          { required: true, message: this.$t('收银订单.自动接单时间(分钟)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isRemind: [
          { required: true, message: this.$t('收银订单.顾客是否催发货 0-否 1-是')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isInvoice: [
          { required: true, message: this.$t('收银订单.是否开屏')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        isDisplay: [
          { required: true, message: this.$t('收银订单.显示标记，1：显示，0：不显示，默认1显示')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        reservation: [
          { required: true, message: this.$t('收银订单.是否需要预约 0：否 1：是')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        goodsType: [
          { required: true, message: this.$t('收银订单.商品类型1.普通商品,2 线下商品')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        source: null,
        type: null,
        parentId: null,
        outTradeNo: null,
        shopId: null,
        shopName: null,
        buyerId: null,
        buyerName: null,
        userMessage: null,
        addressDetail: null,
        activityId: null,
        activityDetail: null,
        orderUserId: null,
        payType: null,
        payTime: null,
        orderStatus: 0,
        refundStatus: 0,
        goodsCount: null,
        orderAmount: null,
        goodsAmount: null,
        payAmount: null,
        packingAmount: null,
        shippingPayAmount: null,
        shippingAmount: null,
        balanceAmount: null,
        outTradeAmount: null,
        preDepositAmount: null,
        preEndAmount: null,
        preProcess: null,
        pointDeductionNum: null,
        pointDeductionAmount: null,
        autoOrdersTime: null,
        isFirstOrder: null,
        isRemind: null,
        isInvoice: null,
        isSplit: null,
        isDisplay: null,
        createTime: null,
        adminRemark: null,
        startTime: null,
        endTime: null,
        reservation: null,
        goodsType: null,
        dateTime: null,
        code: null,
        orderNo: null,
        shopLogo: null,
        couponMoney: null,
        couponId: null,
        platformMoney: null,
        lastRefundTime: null,
        userLiveMoney: null,
        liveId: null,
        buyerMessage: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCashier({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateCashier(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addCashier(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

import request from '@/utils/request'


// 查询银行支行,来源于支付宝列表
export function listBankBranch(query) {
  return request({
    url: '/merchant/bank/branch/list',
    method: 'get',
    params: query
  })
}

// 查询银行支行,来源于支付宝分页
export function pageBankBranch(query) {
  return request({
    url: '/merchant/bank/branch/page',
    method: 'get',
    params: query
  })
}

// 查询银行支行,来源于支付宝详细
export function getBankBranch(data) {
  return request({
    url: '/merchant/bank/branch/detail',
    method: 'get',
    params: data
  })
}

// 新增银行支行,来源于支付宝
export function addBankBranch(data) {
  return request({
    url: '/merchant/bank/branch/add',
    method: 'post',
    data: data
  })
}

// 修改银行支行,来源于支付宝
export function updateBankBranch(data) {
  return request({
    url: '/merchant/bank/branch/edit',
    method: 'post',
    data: data
  })
}

// 删除银行支行,来源于支付宝
export function delBankBranch(data) {
  return request({
    url: '/merchant/bank/branch/delete',
    method: 'post',
    data: data
  })
}
